export const getColumnDefinitions = () => {
    return [
        {
            headerName: '',
            field: 'checkBox',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            hide: false,
            minWidth: 54,
            filter: false,
            width: 54
        },
        {headerName: 'Transaction ID', field: 'hashKey', enableRowGroup: true, hide: false},
        {headerName: 'Use case', field: 'useCase', hide: false},
        {headerName: 'titularnomext', field: 'titularnomext', hide: false, editable: false},
        {headerName: 'titularnom', field: 'titularnom', hide: false, editable: false},
        {headerName: 'titularnif', field: 'titularnif', hide: false, editable: false},
        {headerName: 'tiponoexencion', field: 'tiponoexencion', hide: false, editable: false},
        {headerName: 'tipolibro', field: 'tipolibro', hide: false, editable: false},
        {headerName: 'tipoimpositivo', field: 'tipoimpositivo', hide: false, editable: false},
        {headerName: 'tipofactura', field: 'tipofactura', hide: false, editable: false},
        {headerName: 'tipodesglose', field: 'tipodesglose', hide: false, editable: false},
        {headerName: 'Snapshot TimeStamp', field: 'snapshot_ts', hide: false, editable: false},
        {headerName: 'servimportenosujeta', field: 'servimportenosujeta', hide: false, editable: false},
        {headerName: 'servimpnosujtai', field: 'servimpnosujtai', hide: false, editable: false},
        {headerName: 'servimpnosuj9', field: 'servimpnosuj9', hide: false, editable: false},
        {headerName: 'servimpnosuj714', field: 'servimpnosuj714', hide: false, editable: false},
        {headerName: 'razoncontraext', field: 'razoncontraext', hide: false, editable: false},
        {headerName: 'razoncontra', field: 'razoncontra', hide: false, editable: false},
        {headerName: 'r_consolidate_invoice_dates', field: 'r_consolidate_invoice_dates', hide: false, editable: false},
        {headerName: 'periodo', field: 'periodo', hide: false, editable: false},
        {headerName: 'operacion', field: 'operacion', hide: false, editable: false},
        {headerName: 'numseriefin', field: 'numseriefin', hide: false, editable: false},
        {headerName: 'numserie', field: 'numserie', hide: false, editable: false},
        {headerName: 'nifemisor', field: 'nifemisor', hide: false, editable: false},
        {headerName: 'nifdestfinal', field: 'nifdestfinal', hide: false, editable: false},
        {headerName: 'nifdeposito', field: 'nifdeposito', hide: false, editable: false},
        {headerName: 'nifcontra', field: 'nifcontra', hide: false, editable: false},
        {headerName: 'Marketplace Id', field: 'marketplaceId', hide: false, editable: false},
        {headerName: 'macrodato', field: 'macrodato', hide: false, editable: false},
        {headerName: 'Legal Name', field: 'legalName', hide: false, editable: true},
        {headerName: 'le_entity_code', field: 'le_entity_code', hide: false, editable: false},
        {headerName: 'invsujpasivo', field: 'invsujpasivo', hide: false, editable: false},
        {headerName: 'invc_uniq_key', field: 'invc_uniq_key', hide: false, editable: false},
        {headerName: 'importetotal', field: 'importetotal', hide: false, editable: false},
        {headerName: 'idsustdestini', field: 'idsustdestini', hide: false, editable: false},
        {headerName: 'idsumpropio', field: 'idsumpropio', hide: false, editable: false},
        {headerName: 'idlr', field: 'idlr', hide: false, editable: false},
        {headerName: 'idimpuesto', field: 'idimpuesto', hide: false, editable: false},
        {headerName: 'idcontratipo', field: 'idcontratipo', hide: false, editable: false},
        {headerName: 'idcontraparte', field: 'idcontraparte', hide: false, editable: false},
        {headerName: 'idcontrapais', field: 'idcontrapais', hide: false, editable: false},
        {headerName: 'idapunte', field: 'idapunte', hide: false, editable: false},
        {headerName: 'Full Name', field: 'fullName', hide: false, editable: true},
        {headerName: 'fecregcontable', field: 'fecregcontable', hide: false, editable: false},
        {headerName: 'fecoperacion', field: 'fecoperacion', hide: false, editable: false},
        {headerName: 'fecfactura', field: 'fecfactura', hide: false, editable: false},
        {headerName: 'ejercicio', field: 'ejercicio', hide: false, editable: false},
        {headerName: 'descoperacion', field: 'descoperacion', hide: false, editable: false},
        {headerName: 'data_src', field: 'data_src', hide: false, editable: false},
        {headerName: 'cuotadeducible', field: 'cuotadeducible', hide: false, editable: false},
        {headerName: 'byr_id_dom', field: 'byr_id_dom', hide: false, editable: false},
        {headerName: 'byr_id', field: 'byr_id', hide: false, editable: false},
        {headerName: 'basesinimp', field: 'basesinimp', hide: false, editable: false},
        {headerName: 'baseimponible_lriva', field: 'baseimponible_lriva', hide: false, editable: false},
        {headerName: 'baseimponible_lrimp', field: 'baseimponible_lrimp', hide: false, editable: false},
        {headerName: 'baseimponible_lrapunte', field: 'baseimponible_lrapunte', hide: false, editable: false},
        {headerName: 'admondestino', field: 'admondestino', hide: false, editable: false},
        {headerName: 'Unresolved exceptions', field: 'unresolvedExceptions', hide: false},
        {headerName: 'Corrected exceptions', field: 'correctedExceptions', hide: false},
        {headerName: 'Confirmed exceptions', field: 'confirmedExceptions', hide: false},
        {headerName: 'Applied filters', field: 'appliedFilters', hide: false},
        {headerName: 'Exception key', field: 'exceptionKey', hide: true},
        {headerName: 'Parent Workflow ID', field: 'parentWorkflowId', hide: true},
        {headerName: 'Child Workflow ID', field: 'childWorkflowId', hide: true},
        {headerName: 'Workflow ID', field: 'workflow', hide: true},
        {headerName: 'Version', field: 'version', hide: true},
        {headerName: 'Job Run ID', field: 'jobRunId', hide: true},
        {
            headerName: 'Draft Changes (Y/N)',
            field: 'draftChanges',
            editable: false,
            valueGetter: params => {
                if (params.data === undefined) return 'N';
                return params.data.draftChanges === undefined ? 'N' : params.data.draftChanges;
            },
        }
    ];
}

export const defaultColumnDefinitions = {
    sortable: true,
    minWidth: 200,
    defaultMinWidth: 200,
    resizable: true,
    floatingFilter: true,
    type: 'editableColumn',
    enablePivot: true,
    editable: false,
    filter: true,
    hide: true,
};