import Button from "@amzn/awsui-components-react/polaris/button";
import React from "react";
import { downloadFile } from "./s3Utils";
import AppConfig from "../../AppConfig";
import Link from "@amzn/awsui-components-react/polaris/link";
import {Flashbar, Spinner} from "@amzn/awsui-components-react";
import Navigator from '../Navigator';
import {getIntermediateDeadline} from "./apiUtils";
import {downloadDocumentBys3PreSignedURL} from "../../app/excalibur/components/excaliburAPIs"
import { OBLIGATION_STATUS } from "../../app/exception/components/common/constants";
import {BULK_ADJUSTMENT_USE_CASE} from "../constants/constants";
import {DST_BULK_ADJUSTMENT_FILE_IDENTIFIER} from "../../app/exception/components/DigitalServicesTax/Constants";

/**
 * This function will convert timestamp into readable format.
 * Example "20220908092927" this will be converted to "2022-09-08 09:29 UTC"
 * @param timeStamp
 * @returns {string}
 */
export function convertToReadableTimeStamp(timeStamp) {
    let year = timeStamp.slice(0, 4);
    let month = timeStamp.slice(4, 6);
    let day = timeStamp.slice(6, 8);
    let hour = timeStamp.slice(8, 10);
    let min = timeStamp.slice(10, 12);
    return [[year, month, day].join("-"), [hour, min].join(":")].join(" ") + " UTC";
}


export function getFileNameButtonFromS3Key(s3Key, s3BucketName,domain) {
    if (s3Key === null) {
        return null;
    }
    let arr = s3Key.split("/");
    let fileName = arr[arr.length - 1];

    return (<div> {fileName}
        <Button iconName="download" variant="inline-icon"
            onClick={() => downloadFile(s3Key,
                AppConfig[domain][s3BucketName], domain)} />
    </div>);
}

export function getFileNameButtonFromDMS(s3Key, useCase, subUseCase, inputFileKey, downloadDocumentDispatcher) {
    if (s3Key === null) return null;
    let arr = inputFileKey.split("/");
    let fileName = arr?.[arr.length - 1]?.split('.')[0] + '_' + s3Key;
    return (<div> {fileName}
        <Button iconName="download" variant="inline-icon"
            onClick={() => downloadDocumentBys3PreSignedURL(s3Key, useCase, subUseCase, downloadDocumentDispatcher)} />
    </div>);
}

export function startEfiling() {
    return (<div>
        <Button iconName="download" variant="inline-icon"
            onClick={() => Navigator.navigate('Filing')} />
    </div>);
}


export function getOnCallContactLink() {
    return (<Link external
        color="inverted"
        externalIconAriaLabel="Opens in a new tab"
        href={"https://oncall.corp.amazon.com/#/view/taskless-wolverines-primary/schedule"}>
        {"@wolverines-primary"}
    </Link>)
}


export function formatStatus(value){
    return statusMap[value];
}

const statusMap = {
    "FILED":"Filed",
    "UNDER_APPROVAL":"Under approval",
    "UNDER_PREPARATION":"Under preparation",
    "NOT_STARTED":"Upcoming",
    "UNDER_REVIEW":"Ready for review",
    "UNDER_FILING":"Filing in progress"
}

export function getFlashBar({ header, content, type }) {
    const flashBarProps =
    {
        header: header,
        type: type,
        content: content,
        dismissible: false,
        id: "flashBar"
    }
        ;
    return <Flashbar items={[flashBarProps]} />
}

export function calculateInstanceSummary(data) {
    let obj = { 
        filingOverdue: 0,
        approvalOverdue: 0,
        reviewOverdue: 0,
        preparationOverdue: 0,
        notStarted: 0,
        underPreparation: 0,
        underReview: 0,
        underApproval: 0,
        underFiling: 0,
        filed: 0,
        filedOverdue: 0
    };
    let currentTime = new Date().getTime();
    let countFilingOverdue = 0, countApprovalOverdue = 0, countReviewOverdue = 0, countPreparationOverdue = 0;
    for (let i = 0; i < data.length; i++) {
        const intermediateDeadline = getIntermediateDeadline(data[i].filingDeadline)
        if (!data[i].status || data[i].status === OBLIGATION_STATUS.NotStarted)
            obj.notStarted++;
        if (data[i].status === OBLIGATION_STATUS.UnderPreparation)
            obj.underPreparation++;
        if (data[i].status === OBLIGATION_STATUS.UnderReview)
            obj.underReview++;
        if (data[i].status === OBLIGATION_STATUS.UnderApproval)
            obj.underApproval++;
        if (data[i].status === OBLIGATION_STATUS.UnderFiling)
            obj.underFiling++;
        if (data[i].status === OBLIGATION_STATUS.Filed)
            obj.filed++;
        if (data[i].status !== OBLIGATION_STATUS.Filed && data[i].filingDeadline <= currentTime) {
            countFilingOverdue++;
            countApprovalOverdue++;
        }
        if ((data[i].status === OBLIGATION_STATUS.UnderReview)
            && intermediateDeadline <= currentTime) {
            countReviewOverdue++;
        }
        if ((data[i].status === OBLIGATION_STATUS.NotStarted || data[i].status === OBLIGATION_STATUS.UnderPreparation)
            && intermediateDeadline <= currentTime) {
            countPreparationOverdue++;
        }
    }
    obj.filingOverdue = countFilingOverdue;
    obj.approvalOverdue = countApprovalOverdue;
    obj.reviewOverdue = countReviewOverdue;
    obj.preparationOverdue = countPreparationOverdue;
    return obj;
}

export function getFilteredDataForUseCaseType(data, useCaseType, filedFilter) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
        if (!filedFilter) {
            if (data[i].workflowType === 'SCHEDULED' && useCaseType.has(data[i].type) && data[i].status !== 'FILED') {
                result.push(data[i]);
            }
        } else {
            if (data[i].workflowType === 'SCHEDULED' && useCaseType.has(data[i].type)) {
                result.push(data[i]);
            }
        }
    }
    return result;
}

export function getDataForUseCaseType(data, useCaseType) {
    const result = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i].workflowType === 'SCHEDULED' && useCaseType.has(data[i].type)) {
            result.push(data[i]);
        }
    }
    return result;
}

export function populateCountryCodeValues(selectedUseCase, dropDownData) {
    if (!selectedUseCase.text)
        return;

    let countryCodeArr = [];

    if (dropDownData?.countryCodeList[selectedUseCase?.text]) {
        let values = dropDownData?.countryCodeList[selectedUseCase?.text];
        for (let j = 0; j < values.length; j++) {
            countryCodeArr.push({ id: j + 1, text: values[j] })
        }
    }

    return countryCodeArr;

}

export function populateUseCaseSelectValues(apiObj) {
    if (!apiObj.countryCodeList) return;
    let useCaseArr = [];
    let useCaseList = Object.keys(apiObj.countryCodeList);
    for (let i = 0; i < useCaseList.length; i++) {
        useCaseArr.push({ id: i + 1, text: useCaseList[i] });
    }

    return useCaseArr;
}

export const statusColorCodes = (status) => {
    switch(status) {
        case "Under Approval":
            return {color: '#a537fd'}
        case "SUCCESS":
        case "Filed":
            return {color: '#097969'}
        case "EXCEPTION":
        case "Under Preparation":
            return{color: '#e68a19'}
        case "FAILURE":
            return {color: '#FF0000'}
        default:
            break;
    }
};

export function convertToInternationalCurrencySystem (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
        // Six Zeroes for Millions
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

                : Math.abs(Number(labelValue));

}

export function restructureJobDetailsData(data) {
    let scheduledJobs = [], adhocJobs = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i].taskCategory === 'ADHOC')
            adhocJobs.push(data[i]);
        else if (data[i].taskCategory === 'SCHEDULED')
            scheduledJobs.push(data[i]);
    }

    if (scheduledJobs.length) {
        scheduledJobs.sort((a, b) => {
            return a?.scheduledExecutionTime < b?.scheduledExecutionTime ? -1 : 1
        });
    }

    if (adhocJobs.length) {
        adhocJobs.sort((a, b) => {
            return a?.adhocReportRequestDate < b?.adhocReportRequestDate ? -1 : 1
        });
    }
    const result = {};
    result['scheduledJobs'] = scheduledJobs;
    result['adhocJobs'] = adhocJobs;
    return result;
}

export const useCaseEnum = {
    FR: {id: "FR", text: "FR", useCase: 'FR', subUseCase: ''},
    AT: {id: "AT", text: "AT", useCase: 'AT', subUseCase: ''},
    HMRC_DATA_SHARING: {id: "HMRC_DATA_SHARING", text: "UK", useCase: 'HMRC_DATA_SHARING', subUseCase: ''},
    UK: {id: "UK", text: "UK", useCase: 'UK', subUseCase: ''},
    NOR: {id: "NOR", text: "NOR", useCase: 'NOR', subUseCase: ''},
    LUX_IOSS: {id: "LUX_IOSS", text: "LUX_IOSS", useCase: 'LUX', subUseCase: 'IOSS'},
    LUX_UOSS: {id: "LUX_UOSS", text: "LUX_UOSS", useCase: 'LUX', subUseCase: 'UOSS'},
    LUX_NUOSS: {id: "LUX_NUOSS", text: "LUX_NUOSS", useCase: 'LUX', subUseCase: 'NUOSS'},
};

export const useCaseConfig = [
    {
        id: "SHARED_ECONOMY",
        text: "Shared Economy",
        items: [
            useCaseEnum.FR
        ]
    },
    {
        id: "DATA_SHARING",
        text: "Data Sharing",
        items: [
            useCaseEnum.AT,
            useCaseEnum.HMRC_DATA_SHARING
        ]
    },
    {
        id: "VAT_FILING",
        text: "VAT Filing",
        items: [
            useCaseEnum.UK
        ]
    },
    {
        id: "EXCALIBUR",
        text: "Excalibur File Conversions",
        items: [
            useCaseEnum.NOR,
            useCaseEnum.LUX_IOSS,
            useCaseEnum.LUX_UOSS,
            useCaseEnum.LUX_NUOSS
        ]
    }
];

export const parseIntValue = (value) => {
    return isNaN(value) ? "-" : parseInt(value).toLocaleString("en-US") || "-";
};

export const parseFloatValue = (value) => {
    return isNaN(value) ? "-" : parseFloat(value).toLocaleString("en-US") || "-";
};

export const getLoadingSpinner = (className) => {
    if (className === undefined){
        className = 'PageLoadingSpinner'
    }
    return(
        <div className={className}>
            <Spinner size="big"/>
            <h3> Loading...</h3>
        </div>
    );
};

export const getBulkAdjustmentTemplateData = (useCase) => {
    switch (useCase) {
        case BULK_ADJUSTMENT_USE_CASE.DST:
            return {
                jsonTemplateData: [
                    {
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.Category] : "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.LegalEntity]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.MarketPlace]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.SubBusiness] : "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.LocalCurrency]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.DSTReportingCurrency]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalTransactions] : "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalDSTScopeTransaction]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalRevenueLocalCurrency]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.TotalRevenueDSTReportingCurrency]: "",
                        [DST_BULK_ADJUSTMENT_FILE_IDENTIFIER.Comment]: "",
                    }
                ],
                templateFileName: "DSTBulkAdjustmentTemplate"
            }
        default :
            return {
                jsonTemplateData: [],
                templateFileName: ""
            }
    }
}

const ColumnNames = Object.freeze({
    TRANSACTION_ID: "transactionId",
    USE_CASE: "useCase",
    SUB_USE_CASE: "subUseCase",
    TAX_IDENTIFIER: "taxIdentifier",
    STATUS: "status",
    START_TIMESTAMP: "startTimestamp",
    CREATED_BY: "createdBy",
    INPUT_FILE_KEY: "inputFileKey",
    GET_RECEIPT: "getReceipt",
    TRANSACTION_DETAILS: "transactionDetails",
    TXN_ARTIFACTS: "txnArtifacts",
    ERROR_DETAILS: "errorDetails"
});

export const FILING_TABLE_COLUMNS = [
    ColumnNames.TRANSACTION_ID,
    ColumnNames.USE_CASE,
    ColumnNames.SUB_USE_CASE,
    ColumnNames.TAX_IDENTIFIER,
    ColumnNames.STATUS,
    ColumnNames.START_TIMESTAMP,
    ColumnNames.CREATED_BY,
    ColumnNames.INPUT_FILE_KEY,
    ColumnNames.GET_RECEIPT,
    ColumnNames.TRANSACTION_DETAILS,
    ColumnNames.TXN_ARTIFACTS,
];

export const TRANSACTION_DETAILS_TABLE_COLUMNS = [
    ColumnNames.TAX_IDENTIFIER,
    ColumnNames.STATUS,
    ColumnNames.INPUT_FILE_KEY,
    ColumnNames.GET_RECEIPT,
    ColumnNames.ERROR_DETAILS,
];