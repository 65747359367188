import {
    calculateInstanceSummary,
    getFilteredDataForUseCaseType,
    getDataForUseCaseType,
    populateCountryCodeValues,
    populateUseCaseSelectValues,
} from "../../../common/utils/commonUtils";
import {
    ADHOC_DATA_SHARING_CASE_TYPE,
    DATA_SHARING_USE_CASE_TYPE,
    DIGITAL_SERVICES_TAX_USE_CASE_TYPE, MOG_USE_CASE_TYPE, SPAIN_SII_USE_CASE_TYPE,
    VAT_RETURN_USE_CASE_TYPE
} from "../Constants/Constants";

export const INITIAL_STATE = {
    initialize:true,
    dataSharingData: [],
    vatReturnData: [],
    mogData: [],
    digitalServicesTaxData:[],
    flashBarItem: [],
    dropDownData: {},
    useCaseArr: [],
    countryCodeArr: [],
    dsSummaryData: {},
    adhocDSSummaryData: {},
    adhocDSData: [],
    vatSummaryData: {},
    mogSummaryData: {},
    braavosReportIdList: [],
    digitalServicesTaxSummaryData:{},
    loading: true,
    braavosAPICallStatus: true,
    spainSIIData: [],
    spainSIISummaryData: {}
};

export const tableReducerInit = initialState =>{
    return initialState;
}

const getFlashBarItem = (type, message, action) => {
    return {
        type: type,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
            action.payload?.dispatcher({type:"DISMISS_FLASHBAR", payload:{}})
        },
        content: (
          <>
           {message}
          </>
        ),
        id: "message_1"
    }
};

export const tableReducer = (state, action) => {
    switch (action.type){
        case "API_RESPONSE":
                const dataSharingData = getDataForUseCaseType(action.payload?.data, DATA_SHARING_USE_CASE_TYPE);
                const digitalServicesTaxData = getDataForUseCaseType(action.payload?.data, DIGITAL_SERVICES_TAX_USE_CASE_TYPE);
                const vatReturnData = getDataForUseCaseType(action.payload?.data, VAT_RETURN_USE_CASE_TYPE);
                const mogData = getDataForUseCaseType(action.payload?.data, MOG_USE_CASE_TYPE);
                const adhocDSData = getDataForUseCaseType(action.payload?.data, ADHOC_DATA_SHARING_CASE_TYPE);
                const spainSIIData = getDataForUseCaseType(action.payload?.data, SPAIN_SII_USE_CASE_TYPE);

                const dsSummaryData = calculateInstanceSummary(dataSharingData);
                const vatSummaryData = calculateInstanceSummary(vatReturnData);
                const mogSummaryData = calculateInstanceSummary(mogData);
                const adhocDSSummaryData = calculateInstanceSummary(adhocDSData);
                const digitalServicesTaxSummaryData = calculateInstanceSummary(digitalServicesTaxData);
                const spainSIISummaryData = calculateInstanceSummary(spainSIIData);

                return {
                    ...state,
                    loading: false,
                    initialize: false,
                    dataSharingData: dataSharingData,
                    vatReturnData: vatReturnData,
                    digitalServicesTaxData: digitalServicesTaxData,
                    mogData: mogData,
                    adhocDSSummaryData: adhocDSSummaryData,
                    dsSummaryData: dsSummaryData,
                    vatSummaryData: vatSummaryData,
                    adhocDSData: adhocDSData,
                    mogSummaryData: mogSummaryData,
                    digitalServicesTaxSummaryData: digitalServicesTaxSummaryData,
                    allData: action.payload?.data,
                    spainSIIData: spainSIIData,
                    spainSIISummaryData: spainSIISummaryData
                }
        case "SET_TABLE_FILTERS": 
                const summaryData = calculateInstanceSummary(action.payload?.data)
                return {
                    ...state,
                    dsSummaryData: summaryData
                }        
        case "POPULATE_COUNTRY_CODE_VALUES":
            const countryCodeArr = populateCountryCodeValues(action.payload?.data, state.dropDownData);
            return {
                ...state,
                countryCodeArr: countryCodeArr,
            }        
        case "CUSTOM_FIELDS_SUCCESS":
            const useCaseList = populateUseCaseSelectValues(action.payload?.data);
            return {
                ...state,
                dropDownData: action.payload?.data,
                useCaseArr:
                useCaseList,
            }
        case "CUSTOM_FIELDS_FAILURE":
                return {
                    ...state
                }
        case "BRAAVOS_GET_REPORTID_SUCCESS":
            return {
                ...state,
                braavosAPICallStatus: true,
                braavosReportIdList: action.payload?.data,
            }
        case "BRAAVOS_GET_REPORTID_FAILURE":
            return {
                ...state,
                braavosAPICallStatus: false
            }
        case "RESET_BRAAVOS_GET_REPORT_API_STATUS":
            return {
                ...state,
                braavosAPICallStatus: true
            }
        case "ADHOC_REQUEST_SUCCESS": 
            const message = action.payload?.msg
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("success", message, action)]
            }    
        case "ADHOC_REQUEST_FAILURE":
            const errMessage = action.payload?.errMsg
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("error", errMessage, action)]
            }
        case "ADHOC_REPORT_REQUEST_SUCCESS":
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("success", action.payload?.msg, action)]
            }
        case "ADHOC_REPORT_REQUEST_FAILURE":
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("error", action.payload?.errMsg, action)]
            }
        case "EXPORT_AGGREGATED_REPORT_SUCCESS":
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("success", action.payload?.msg, action)]
            }
        case "EXPORT_AGGREGATED_REPORT_FAILURE":
            return {
                ...state,
                ...action.payload,
                flashBarItem: [getFlashBarItem("error", action.payload?.errMsg, action)]
            }
        case "DISMISS_FLASHBAR":
                return {
                    ...state,
                    flashBarItem: []
                } 
        case "APPLY_FILTER":
            const dataSharing = getFilteredDataForUseCaseType(state.allData,DATA_SHARING_USE_CASE_TYPE, action.payload?.filedFilter);
            const sum = calculateInstanceSummary(dataSharing);
            return {
                ...state,
                dataSharingData: dataSharing,
                dsSummaryData: sum,
            }             
        default:
            return state
    }
}