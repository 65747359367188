export const OBLIGATION_STATUS = {
    UnderApproval: "UNDER_APPROVAL",
    UnderFiling: "UNDER_FILING",
    Filed: "FILED",
    UnderReview: "UNDER_REVIEW",
    UnderPreparation: "UNDER_PREPARATION",
    NotStarted: "NOT_STARTED",
};

export const ASM_COMPLETE_STATUS = "COMPLETE";
export const ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY = {
    Trends: "TRENDS",
    Audit: "AUDIT",
    Checklist: "CHECKLIST",
    Exceptions: "EXCEPTIONS",
    Summary: "SUMMARY",
    TransactionReview: "TRANSACTION_REVIEW",
    Insight: "INSIGHT",
};

export const PHASTOS_OBLIGATION = {
    MOG: "MOG",
    VAT: "VAT",
    DS: "DS",
    DST:"DST",
    SPAIN_SII: "SPAIN_SII"
};

export const BULK_ADJUSTMENT_FORMATS = ".xlsx,.xls";
export const BULK_ADJUSTMENT_MAX_FILE_SIZE = 2 * 1024 * 1024;
export const BULK_ADJUSTMENT_MAX_ALLOWED_ROWS = 200;
export const TRANSACTION_CONTAINER_DOM_ID = "transactions-container";