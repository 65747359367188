import React from "react";
import {CollectionPreferences} from "@amzn/awsui-components-react";

export const getColumnDefinitions = [];

export const getVisibleContent = () => {
    return [];
}

export const getFilteringProperties = () => {
    return [];
}

export const getPreferenceOptions = () => {
    return [];
}

const VISIBLE_CONTENT_OPTIONS = [
    {
        label: 'Main transaction properties',
        options: [

        ],
    },
];

export const Preferences = ({
                                preferences,
                                setPreferences,
                                disabled,
                                visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
                            }) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => setPreferences(detail)}
        wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines',
        }}
        visibleContentPreference={{
            title: 'Select visible columns',
            options: visibleContentOptions,
        }}
    />
);