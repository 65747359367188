import React, { useEffect } from "react";
import Table from "./SourceDataSummaryTable"
import TransactionCategoryTable from "./TransactionCategoryTable"
import {
    Grid, SpaceBetween
} from '@amzn/awsui-components-react';
import "./css/SourceSummary.css"
import TaxCodeBreakdownTable from "./TaxCodeBreakdownTable";
import { PHASTOS_OBLIGATION } from "../exception/components/common/constants";

function SourceSummary(params) {
    const { workflowData, sourceSummaryData, sourceSummaryTableDispatcher, refreshSourceSummaryData, phastosObligation } = params;

    const [
        selectedItems,
        setSelectedItems
    ] = React.useState([]);

    const refreshSourceSummaryBtnHandler = () => {
        refreshSourceSummaryData();
        setSelectedItems([]);
    }

    useEffect(() => {
        setSelectedItems([]);
    }, []);

    const getObligationSpecificViews = (phastosObligation) => {
        switch (phastosObligation) {
            case PHASTOS_OBLIGATION.MOG:
            case PHASTOS_OBLIGATION.DS:
            case PHASTOS_OBLIGATION.SPAIN_SII:
                return (
                    <TransactionCategoryTable {...params} sourceSummaryData={sourceSummaryData} selectedItems={selectedItems} phastosObligation={phastosObligation}/>
                );
            case PHASTOS_OBLIGATION.VAT:
            default:
                return (
                    <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
                        <TransactionCategoryTable {...params} sourceSummaryData={sourceSummaryData} selectedItems={selectedItems} phastosObligation={phastosObligation}/>
                        <TaxCodeBreakdownTable {...params} sourceSummaryData={sourceSummaryData} selectedItems={selectedItems}/>
                    </Grid>
                );
        }
    };

    return (
        <>
            <div>
                <SpaceBetween
                    direction="vertical"
                    size="xxl"
                >
                    <div>
                        <Table 
                            {...params}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            workflowData={workflowData}
                            sourceSummaryData={sourceSummaryData}
                            sourceSummaryTableDispatcher={sourceSummaryTableDispatcher}
                            refreshSourceSummaryData={refreshSourceSummaryBtnHandler}
                            phastosObligation={phastosObligation}
                        />
                    </div>
                    {getObligationSpecificViews(phastosObligation)}
                </SpaceBetween>
            </div>
        </>
    );
}

export default SourceSummary;