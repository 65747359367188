import { API } from "aws-amplify";
import { getAPIInputContext } from "../../../../../../common/utils/apiUtils";
import {
    PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS
} from "../../../../../../ApiPaths";
import {isNaN, isNil, isNull, isUndefined} from "lodash";
import {TRANSACTION_CONTAINER_DOM_ID} from "../../../VAT/constants/boxLevelConstants";
import {SHIELD_METRIC_TYPES} from "../../../VAT/constants/errorSummaryConstants";
import { getExceptionStatusMapping } from "../../../../utils/utils";
import {
    MOG_EXCEPTION_SEVERITY_MAPPING,
    MOG_EXCEPTION_STATUS_MAPPING
} from "../../../MOG-V2/constants/errorSummaryConstants";
import {PHASTOS_OBLIGATION} from "../../constants";

export const TRANSACTION_TYPE = '_transaction_type';
export const ERROR_TYPE_EXCEPTION = 'Exception';
export const ERROR_TYPE_EXCLUDED = 'Excluded';

const TRANSACTION_CATEGORY_MAP = {
    Excluded: "filterTag",
    Exception: "exceptionTag"
}

const setTransactionBreakdownDeeplinkProps = (params) => {
    const conditionList = [{
        attributeName:TRANSACTION_TYPE,
        attributeValue:params.type,
        condition:'EQUALS'
    }];
    if (!isNil(params.category))
        conditionList.push({
            attributeName: TRANSACTION_CATEGORY_MAP[params.type],
            attributeValue: params.category,
            condition: 'EQUALS'
        });
    if(params.type === ERROR_TYPE_EXCEPTION) {
        if (params.status !== undefined)
            conditionList.push({
                attributeName: 'exceptionStatus',
                attributeValue: params.status,
                condition: 'EQUALS'
            })
        if (params.severity !== undefined)
            conditionList.push({
                attributeName: 'severity',
                attributeValue: params.severity,
                condition: 'EQUALS'
            })
    }
    return conditionList;
}

export const showTransactionsHandler = (params, setCurrentFilter, setTransactionFilterData, setShowTransactions, phastosObligation) => {
    const exceptionStatusMapping = getExceptionStatusMapping(phastosObligation);
    let filter = "";
    filter += params.type;
    filter += params.category === undefined ? "" : ` > ${params.category}`;
    filter += params.status === undefined ? "" : isNull(exceptionStatusMapping) ? ` > ${params.status}` : ` > ${exceptionStatusMapping[params.status]}`;
    setCurrentFilter(filter);
    setTransactionFilterData({
        filterConditions:setTransactionBreakdownDeeplinkProps(params),
    });
    setShowTransactions(true);

    setTimeout(() => {
        const element = document.getElementById(TRANSACTION_CONTAINER_DOM_ID);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, 300);
};

const getEmptyExceptionSummaryItem = (exceptionSummaryItem, exceptionStatus) => {
    return {
        exceptionCount: 0,
        exceptionStatus: exceptionStatus,
        exceptionStatusName: MOG_EXCEPTION_STATUS_MAPPING[exceptionStatus],
        invoiceValue: null,
        severity: exceptionSummaryItem.severity,
        severityName: MOG_EXCEPTION_SEVERITY_MAPPING[exceptionSummaryItem.severity],
        statisticalValue: null,
        transactionCategory: exceptionSummaryItem.transactionCategory,
        transactionCount: 0,
        transactionType: exceptionSummaryItem.transactionType
    };
};

// Descending Order
const compareSummaryItemsByCount = (item1, item2) => {
    return item2.transactionCount > item1.transactionCount;
};

// Ordered
const exceptionStatuses = ["Unresolved", "Corrected", "Confirmed"];

// TODO - Move to TwinService if the logic needs to be reused across other components or needs to support other operations
const sortExceptionSummaryItems = (exceptionSummaryList) => {
    // Sort Excluded
    const excludedItems = exceptionSummaryList.filter(e => e.transactionType === 'Excluded');
    excludedItems.sort(compareSummaryItemsByCount);

    const exceptionItems = exceptionSummaryList.filter(e => e.transactionType === 'Exception');

    // Sort Unresolved Exceptions
    const unresolvedExceptionItems = exceptionItems.filter(e => e.exceptionStatus === 'Unresolved');
    unresolvedExceptionItems.sort(compareSummaryItemsByCount);

    // Fill based on above order
    const sortedExceptionItems = [];
    unresolvedExceptionItems.forEach(unresolvedItem => {
        exceptionStatuses.forEach(exceptionStatus => {
            sortedExceptionItems.push(exceptionItems.find(exceptionItem => {
                return exceptionItem.transactionCategory === unresolvedItem.transactionCategory &&
                exceptionItem.exceptionStatus === exceptionStatus;
            }));
        });
    });

    return excludedItems.concat(sortedExceptionItems);
};

const processSummaryItems = (exceptionSummaryList) => {
    let extendedExceptionSummaryList = exceptionSummaryList;
    const exceptionSet = new Set();
    let exceptionMap = {};
    exceptionSummaryList.forEach(element => {
        if (element.transactionType === 'Exception') {
            exceptionSet.add(element.transactionCategory);
            exceptionMap = {
                ...exceptionMap,
                [element.transactionCategory]: {
                    ...exceptionMap[element.transactionCategory],
                    [element.exceptionStatus]: element
                }
            }
        }
    });

    // Add 0 counts
    Object.entries(exceptionMap).forEach(entry => {
        exceptionStatuses.forEach(exceptionStatus => {
            const exception = exceptionMap[entry[0]];
            if (exception[exceptionStatus] === undefined) {
                extendedExceptionSummaryList.push(getEmptyExceptionSummaryItem(Object.values(exception)[0], exceptionStatus));
            }
        });
    });

    return sortExceptionSummaryItems(extendedExceptionSummaryList);
};

const compareSummaryItems = (item1, item2) => {
    return (item2.transactionType + item2.transactionCategory + item2.exceptionStatus).localeCompare((item1.transactionType + item1.transactionCategory + item1.exceptionStatus));
};

export const getExceptionTransactionDetails = (request) => {
    getAPIInputContext().then(input => {
        input.body.parentWorkflowId = request.parentWorkflowId;
        input.body.useCase = request.useCase;
        input.body.metricType = SHIELD_METRIC_TYPES.TRANSACTION_REVIEW_SUMMARY;

        API.post("TWIN_API", PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS, input).then(resp => {
            if (resp.status === 200) {
                const payload = JSON.parse(resp.body.data);
                let exceptionSummaryList = isNull(payload.transactionSummaryList) ? [] : payload.transactionSummaryList.map(e => JSON.parse(e));
                request.setItems(getTransactionSummaryResult(request.phastosObligation, exceptionSummaryList));
                request.setIsLoading(false);
            } else {
                console.log(PHASTOS_GET_TRANSACTION_SUMMARY_DETAILS + " API Error Response : " + JSON.stringify(resp));
            }
        });
    });
};

const getTransactionSummaryResult = (phastosObligation, exceptionSummaryList) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return processSummaryItems(exceptionSummaryList);
        default:
            return exceptionSummaryList.sort(compareSummaryItems);
    }
};

export const getCurrencyAmountForSummary = (amount, currencySymbol) => {
    const count = parseFloat(amount);
    if (isNaN(count)) {
        return <div style={{float: "right"}}>-</div>;
    }
    const parsedCount = count.toLocaleString("en-US", {
        minimumFractionDigits: 2, maximumFractionDigits: 2
    });
    if (isUndefined(currencySymbol) || isNull(currencySymbol)) {
        return <div style={{float: "right"}}>{parsedCount}</div>;
    } else {
        return <div style={{float: "right"}}>{currencySymbol+" "+ parsedCount}</div>;
    }
};