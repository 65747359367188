import React, { useState } from 'react';
import {
    Grid,
    Container,
    SpaceBetween,
    Toggle,
    Header,
    Box,
    Button,
    Link
} from "@amzn/awsui-components-react";
import { Icon } from "@amzn/awsui-components-react/polaris";
import { getUserAlias } from "../../utils/utils";
import { modifyWorkflowStatus } from "../redux/exceptionAPIs";
import { postMessage } from '../../../phastos/components/Slack/slackIntegrationAPIs';
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS, OBLIGATION_STATUS } from '../common/constants';
import {APPROVER_ROLE, REVIEWER_ROLE} from "../../../../common/constants/constants";

export default function SpainSIIChecklists(props) {
    const {
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        isUnderReview,
        stepsState,
        parentWorkflowId,
        setActiveTabId,
        checklistState,
        setChecklistState,
        obligationStatus,
        userRole,
        slackChannel
    } = props;
    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Checklist]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has completed the Checklists step.`);
            setStepsStateHandler("checklistStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const isCompleteButtonDisabled = () => {
        return !(
            isUnderReview && !stepsState.checklistStep.completed
            && Object.values(checklistState.preparer).every(Boolean)
        );
    };

    const SYSTEM_CHECKLIST_OPTIONS = [
        "Validated that all relevant data sources have been ingested completely and processed.",
        "100% of transactions ingested have been evaluated for reporting i.e. there is no loss of data from ingestions stage to final calculation stage.",
        "Stored complete audit trail of transactions along with intermediary and output files.",
        "Logged all user activities and data changes.",
        "Validated security control to ensure sensitive data protection.",
        "Validated all mandatory attributes are present in data."
    ];

    const getChecklistTextDiv = (text) => {
        return (
            <div>
                <Icon variant="success" name="status-positive"/>
                {text}
            </div>
        )
    }

    return (
        <Grid gridDefinition={[{colspan: 6}, {colspan: 6}, {colspan: 6}]}>
            <Container header={<Header>System checklist</Header>}>
                <SpaceBetween size="s" direction="vertical">
                    {SYSTEM_CHECKLIST_OPTIONS.map((option) => {return getChecklistTextDiv(option)})}
                </SpaceBetween>
            </Container>
            <Container
                header={
                    <Header actions={
                        <Button onClick={completeButtonClickHandler} disabled={isCompleteButtonDisabled() || completeButtonState}>
                            Complete
                        </Button>
                    }>
                        Manual Checklist
                    </Header>
                }>
                <SpaceBetween size="s" direction="vertical">
                    <Box variant="h2">Preparer</Box>
                    <Toggle
                        checked={checklistState.preparer.option1}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option1: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I’ve reviewed the"} <Link onFollow={()=>{setActiveTabId("first")}}>aggregated summary</Link> {"for this obligation."}</div>}
                    </Toggle>
                    <Toggle
                        checked={checklistState.preparer.option2}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option2: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I’ve reviewed, corrected and considered the impact of accepted"} <Link onFollow={()=>{setActiveTabId("fourth")}}>exceptions</Link>{", highlighted for this obligation."}</div>}
                    </Toggle>
                    <Toggle
                        checked={checklistState.preparer.option3}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderApproval || stepsState.checklistStep.completed) || userRole===APPROVER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                preparer: {
                                    ...prevState.preparer,
                                    option3: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've reviewed all"} <Link onFollow={()=>{setActiveTabId("fifth")}}>adjustments</Link> {"made against misclassified transactions or incorrect data."}</div>}
                    </Toggle>
                </SpaceBetween>
                <br />
                <SpaceBetween size="s" direction="vertical">
                    <Box variant="h2">Approver</Box>
                    <Toggle
                        checked={checklistState.approver.option1}
                        disabled={(obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed) || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option1: detail.checked,
                                }
                            }
                        })}
                    >
                        I've reviewed the reviewers checklist.
                    </Toggle>
                    <Toggle
                        checked={checklistState.approver.option2}
                        disabled={obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option2: detail.checked,
                                }
                            }
                        })}
                    >
                        {<div>{"I've reviewed that the"} <Link onFollow={()=>{setActiveTabId("fifth")}}>adjustments</Link> {"are logical, reasonable and supported by relevant evidence."}</div>}
                    </Toggle>
                    <Toggle
                        checked={checklistState.approver.option3}
                        disabled={obligationStatus === OBLIGATION_STATUS.UnderFiling || obligationStatus === OBLIGATION_STATUS.Filed || userRole === REVIEWER_ROLE}
                        onChange={({detail}) => setChecklistState(prevState => {
                            return {
                                ...prevState,
                                approver: {
                                    ...prevState.approver,
                                    option3: detail.checked,
                                }
                            }
                        })}
                    >
                        I've reviewed the systematic checks and controls in the system
                    </Toggle>
                </SpaceBetween>
            </Container>
        </Grid>
    )
}