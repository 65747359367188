export const ROUTE_PATH_HOME_PAGE = "/";
export const ROUTE_PATH_PHASTOS_PAGE = "/phastos";
export const ROUTE_PATH_NOT_FOUND_PAGE = "/not-found";
export const ROUTE_DEFAULT = "*";
export const ROUTE_EXCALIBUR = "/excalibur";
export const ROUTE_PATH_PHASTOS_DETAILS_PAGE = "/phastos/details";
export const ROUTE_PATH_FILING_PAGE = "/filing";
export const ROUTE_PATH_TRANSACTION_DETAILS_PAGE = "/filing/transactiondetails"
export const ROUTE_PATH_FILING_HMRC_PAGE = "/filinghmrc";
export const ROUTE_PATH_FAQ_PAGE = "/faq";
export const ROUTE_CONFIGURATIONS = "/configurations";
export const ROUTE_DOCUMENT_MANAGER = "/documentmanagement";
export const ROUTE_SOURCE_SUMMARY = "/sourcesummary";
