import { useLocation, useNavigate } from "react-router-dom";
import { get, isUndefined } from "lodash";
import DSException from "./components/DS-V1/DSException-V1";
import DSExceptionV2 from "./components/DS-V2/DSException-V2";
import VATException from "./components/VAT/VATException";
import MOGException from "./components/MOG-V2/MOGException";
import { useEffect, useReducer, useState } from "react";
import { GET_MESSAGES_SLACK_INITIAL_STATE, getMessagesSlackDataReducer, getMessagesSlackDataReducerInit } from "../phastos/components/Slack/getMessagesSlackReducer";
import { filterTextMessages, processNewMessage, processTextMessages, processUsers } from "./components/common/correspondenceUtils";
import { getMessages } from "../phastos/components/Slack/slackIntegrationAPIs";
import { EXCEPTION_INITIAL_STATE, exceptionReducer, exceptionReducerInit } from "./components/redux/exceptionTableReducer";
import { ROUTE_PATH_PHASTOS_PAGE } from "../../common/constants/route_paths";
import { UPDATE_STATUS_INITIAL_STATE, updateStatusReducer, updateStatusReducerInit } from "./components/redux/updateStatusReducer";
import { getPhastosObligationFromWorkflowType } from "./utils/utils";
import { PHASTOS_OBLIGATION } from "./components/common/constants";
import DSTException from "./components/DigitalServicesTax/DSTException";
import SpainSIIException from "./components/SPAIN_SII/SpainSIIException";

function WorkflowDetails(props) {
    const { domain, user } = props;
    const params = useLocation();
    const itemData = get(params, 'state.data', undefined);
    const slackChannel = get(params, 'state.slackChannel', undefined);
    const workflowType = get(itemData, 'type', 'DATA_SHARING');
    const phastosObligation = getPhastosObligationFromWorkflowType(workflowType);
    const isAdjustmentEnabled = itemData?.featureFlags?.is_adjustment_enabled;
    
    const [exceptionSummaryData, exceptionSummaryDispatcher] = useReducer(exceptionReducer,
        EXCEPTION_INITIAL_STATE, exceptionReducerInit);
    const [updateWorkflowStatus, updateWorkflowStatusDispatcher] = useReducer(updateStatusReducer,
        UPDATE_STATUS_INITIAL_STATE, updateStatusReducerInit);

    const navigate = useNavigate();

    const SLACK_MESSAGES_REFRESH_TIME_MS = 5000;

    useEffect(() => {
        if (isUndefined(itemData)) {
            navigate(ROUTE_PATH_PHASTOS_PAGE);
            return;
        }

        // TODO: - Add actual API call over here & verify usecase
        let requestId = localStorage.getItem("requestId");
        exceptionSummaryDispatcher({
            type: "SET_REQUEST_ID", payload: {
                data: {
                    requestId,
                }
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [getMessagesSlackData, getMessagesSlackDataDispatcher] = useReducer(getMessagesSlackDataReducer,
        GET_MESSAGES_SLACK_INITIAL_STATE, getMessagesSlackDataReducerInit);

    const [intervalID, setIntervalID] = useState(0);
    const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] = useState(false);

    useEffect(() => {
        if (shouldIntervalBeCancelled) {
            clearInterval(intervalID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldIntervalBeCancelled]);

    const [correspondenceMessages, setCorrespondenceMessages] = useState([]);
    const [correspondenceLoading, setCorrespondenceLoading] = useState(false);

    useEffect(() => {
        const messages = getMessagesSlackData.data?.messages;
        if (messages === undefined) return;

        const textMessages = filterTextMessages(messages);
        const userIds = processUsers(textMessages);
        (async () => {
            processTextMessages(userIds, textMessages, setCorrespondenceMessages);
            setCorrespondenceLoading(false);
        })();

        // eslint-disable-next-line
    }, [getMessagesSlackData]);

    const handleNewMessage = async (newMessage) => {
        processNewMessage(slackChannel, newMessage, correspondenceMessages, setCorrespondenceMessages);
    };

    const handleToolsChange = (event) => {
        if (event.detail.open === true) {
            if (slackChannel !== undefined) {
                setCorrespondenceLoading(true);
                getMessages(slackChannel, getMessagesSlackDataDispatcher);
            }

            let intervalID = setInterval(() => {
                if (slackChannel !== undefined) {
                    getMessages(slackChannel, getMessagesSlackDataDispatcher);
                }
            }, SLACK_MESSAGES_REFRESH_TIME_MS);
            setIntervalID(intervalID);
            setShouldIntervalBeCancelled(false);
        } else {
            setShouldIntervalBeCancelled(true);
            setCorrespondenceMessages([]);
        }
    };

    const getObligationSpecificException = (phastosObligation) => {
        switch (phastosObligation) {
            case PHASTOS_OBLIGATION.DS:
                return isAdjustmentEnabled ? (<DSExceptionV2
                    itemData={itemData}
                    slackChannel={slackChannel}
                    correspondenceMessages={correspondenceMessages}
                    correspondenceLoading={correspondenceLoading}
                    handleToolsChange={handleToolsChange}
                    handleNewMessage={handleNewMessage}
                    exceptionSummaryData={exceptionSummaryData}
                    updateWorkflowStatus={updateWorkflowStatus}
                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                />) : (<DSException
                    itemData={itemData}
                    slackChannel={slackChannel}
                    correspondenceMessages={correspondenceMessages}
                    correspondenceLoading={correspondenceLoading}
                    handleToolsChange={handleToolsChange}
                    handleNewMessage={handleNewMessage}
                    exceptionSummaryData={exceptionSummaryData}
                    updateWorkflowStatus={updateWorkflowStatus}
                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                />);
            case PHASTOS_OBLIGATION.MOG:
                return (<MOGException
                    alias={user}
                    itemData={itemData}
                    slackChannel={slackChannel}
                    correspondenceMessages={correspondenceMessages}
                    correspondenceLoading={correspondenceLoading}
                    handleToolsChange={handleToolsChange}
                    handleNewMessage={handleNewMessage}
                    exceptionSummaryData={exceptionSummaryData}
                    updateWorkflowStatus={updateWorkflowStatus}
                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                    domain={domain}
                />);
            case PHASTOS_OBLIGATION.DST:
                return (<DSTException
                    {...props}
                    itemData={itemData}
                    slackChannel={slackChannel}
                    correspondenceMessages={correspondenceMessages}
                    correspondenceLoading={correspondenceLoading}
                    handleToolsChange={handleToolsChange}
                    handleNewMessage={handleNewMessage}
                    exceptionSummaryData={exceptionSummaryData}
                    updateWorkflowStatus={updateWorkflowStatus}
                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                />);
            case PHASTOS_OBLIGATION.SPAIN_SII:
                return (<SpainSIIException
                    domain={domain}
                    itemData={itemData}
                    slackChannel={slackChannel}
                    correspondenceMessages={correspondenceMessages}
                    correspondenceLoading={correspondenceLoading}
                    handleToolsChange={handleToolsChange}
                    handleNewMessage={handleNewMessage}
                    exceptionSummaryData={exceptionSummaryData}
                    updateWorkflowStatus={updateWorkflowStatus}
                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                />)
            case PHASTOS_OBLIGATION.VAT:
            default:
                return (<VATException
                    domain={domain}
                    itemData={itemData}
                    slackChannel={slackChannel}
                    correspondenceMessages={correspondenceMessages}
                    correspondenceLoading={correspondenceLoading}
                    handleToolsChange={handleToolsChange}
                    handleNewMessage={handleNewMessage}
                    exceptionSummaryData={exceptionSummaryData}
                    updateWorkflowStatus={updateWorkflowStatus}
                    updateWorkflowStatusDispatcher={updateWorkflowStatusDispatcher}
                />);
        }
    };
    
    return (
        <div>
            {getObligationSpecificException(phastosObligation)}
        </div>
    );
}

export default WorkflowDetails;