import { OBLIGATION_STATUS } from "../../exception/components/common/constants";
import { getChannelNameFromObligation, getChannelNameFromVATObligation } from "../components/Slack/slackUtils";
import { EmptyState } from "../table-config";
import { createChannel } from '../components/Slack/slackIntegrationAPIs';
import { Icon } from "@amzn/awsui-components-react";

export const SUMMARY_METRICS = {
    NOT_STARTED: {
        label: 'Upcoming',
        key: 'notStarted',
        isOverdueShow: false,
        actualStatus: 'NOT_STARTED',
        overdueKey: '',
    },
    UNDER_PREPARATION: {
        label: 'Under preparation',
        key: 'underPreparation',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderPreparation,
        overdueKey: 'preparationOverdue',
    },
    UNDER_REVIEW: {
        label: 'Ready for review',
        key: 'underReview',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderReview,
        overdueKey: 'reviewOverdue',
    },
    READY_FOR_APPROVAL: {
        label: 'Ready for approval',
        key: 'underApproval',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderApproval,
        overdueKey: 'approvalOverdue',
    },
    FILING_IN_PROGRESS: {
        label: 'Filing in progress',
        key: 'underFiling',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderFiling,
        overdueKey: 'filingOverdue',
    },
    FILED: {
        label: 'Filed',
        key: 'filed',
        isOverdueShow: false,
        actualStatus: OBLIGATION_STATUS.Filed,
        overdueKey: '',
    },
}

export const VAT_SUMMARY_METRICS = {
    UNDER_PREPARATION: {
        label: 'Under preparation',
        key: 'underPreparation',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderPreparation,
        overdueKey: 'preparationOverdue',
    },
    UNDER_REVIEW: {
        label: 'Ready for review',
        key: 'underReview',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderReview,
        overdueKey: 'reviewOverdue',
    },
    READY_FOR_APPROVAL: {
        label: 'Ready for approval',
        key: 'underApproval',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderApproval,
        overdueKey: 'approvalOverdue',
    },
    FILING_IN_PROGRESS: {
        label: 'Filing in progress',
        key: 'underFiling',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderFiling,
        overdueKey: 'filingOverdue',
    },
    FILED: {
        label: 'Filed',
        key: 'filed',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.Filed,
        overdueKey: 'filedOverdue',
    },
};

export const MOG_SUMMARY_METRICS = {
  UNDER_PREPARATION: {
      label: 'Preparation',
      key: 'underPreparation',
      isOverdueShow: true,
      actualStatus: OBLIGATION_STATUS.UnderPreparation,
      overdueKey: 'preparationOverdue',
  },
  UNDER_REVIEW: {
      label: 'Review',
      key: 'underReview',
      isOverdueShow: true,
      actualStatus: OBLIGATION_STATUS.UnderReview,
      overdueKey: 'reviewOverdue',
  },
  READY_FOR_APPROVAL: {
      label: 'Approval',
      key: 'underApproval',
      isOverdueShow: true,
      actualStatus: OBLIGATION_STATUS.UnderApproval,
      overdueKey: 'approvalOverdue',
  },
  FILING_IN_PROGRESS: {
      label: 'Filing',
      key: 'underFiling',
      isOverdueShow: true,
      actualStatus: OBLIGATION_STATUS.UnderFiling,
      overdueKey: 'filingOverdue',
  },
  FILED: {
      label: 'Completed',
      key: 'filed',
      isOverdueShow: true,
      actualStatus: OBLIGATION_STATUS.Filed,
      overdueKey: 'filedOverdue',
  },
};

export const DIGITAL_SERVICES_TAX_SUMMARY_METRICS = {
    UNDER_PREPARATION: {
        label: 'Under preparation',
        key: 'underPreparation',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderPreparation,
        overdueKey: 'preparationOverdue',
    },
    UNDER_REVIEW: {
        label: 'Ready for review',
        key: 'underReview',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderReview,
        overdueKey: 'reviewOverdue',
    },
    READY_FOR_APPROVAL: {
        label: 'Ready for approval',
        key: 'underApproval',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderApproval,
        overdueKey: 'approvalOverdue',
    },
    APPROVED: {
        label: 'Approved',
        key: 'approved',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.Filed,
        overdueKey: '',
    }
};

export const SPAIN_SII_SUMMARY_METRICS = {
    NOT_STARTED: {
        label: 'Upcoming',
        key: 'notStarted',
        isOverdueShow: false,
        actualStatus: 'NOT_STARTED',
        overdueKey: '',
    },
    UNDER_PREPARATION: {
        label: 'Under preparation',
        key: 'underPreparation',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderPreparation,
        overdueKey: 'preparationOverdue',
    },
    UNDER_REVIEW: {
        label: 'Ready for review',
        key: 'underReview',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderReview,
        overdueKey: 'reviewOverdue',
    },
    READY_FOR_APPROVAL: {
        label: 'Ready for approval',
        key: 'underApproval',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderApproval,
        overdueKey: 'approvalOverdue',
    },
    FILING_IN_PROGRESS: {
        label: 'Filing in progress',
        key: 'underFiling',
        isOverdueShow: true,
        actualStatus: OBLIGATION_STATUS.UnderFiling,
        overdueKey: 'filingOverdue',
    },
    FILED: {
        label: 'Filed',
        key: 'filed',
        isOverdueShow: false,
        actualStatus: OBLIGATION_STATUS.Filed,
        overdueKey: '',
    },
};

export const getCollectionConfig = (filteringProperties, tablePreferences, sortingColumn) => {
    return {
        filtering: {
          empty: (
            <EmptyState
              title="No instances"
              subtitle="No instances to display."
            />
          ),
          noMatch: (
            <EmptyState
              title="No matches"
              subtitle="We can’t find a match."
            />
          ),
        },
        propertyFiltering: {
          filteringProperties,
          empty: <EmptyState title="No matches" />,
          noMatch: (
            <EmptyState
              title="No matches"
            />
          ),
        },
        pagination: { pageSize: tablePreferences.pageSize },
        sorting: { defaultState: { sortingColumn: sortingColumn } },
        selection: {}
    };
};

export const handleObligationClick = (value, navigate, listChannelsSlackData, isVATReturn) => {
  if (value) {
    const channelName = isVATReturn ? getChannelNameFromVATObligation(value) : getChannelNameFromObligation(value);
    const filteredChannels = listChannelsSlackData.data.channels.filter(channel => channel.name === channelName);
    navigate("./details", {
      state: {
        data: value,
        slackChannel: filteredChannels.length > 0 ? filteredChannels[0].id : undefined,
      }
    });
  }
};

export const createSlackChannel = (value, createChannelSlackStatusDispatcher, listChannelsSlackDataDispatcher, isVATReturn) => {
  if (value) {
    const channelName = isVATReturn ? getChannelNameFromVATObligation(value) : getChannelNameFromObligation(value);
    createChannel(channelName, createChannelSlackStatusDispatcher, listChannelsSlackDataDispatcher);
  }
};

export const handleAddUserSlackClick = (value, dispatcher, isVATReturn) => {
  if (value) {
    dispatcher({ type: "SHOW_MODAL", payload: { channelName: isVATReturn ? getChannelNameFromVATObligation(value) : getChannelNameFromObligation(value) } });
  }
};

const DEFAULT_COLOR = '#000000';
const WARNING_COLOR = '#D13212';
const SUCCESS_COLOR = '#1D8102';

const getDeadlineConfig = (eligibleItemStatus, intermediateDeadline) => {
  let icon = null;
  let color = DEFAULT_COLOR;
  let currentTime = new Date().getTime();
  if (eligibleItemStatus) {
    if (currentTime > intermediateDeadline) {
      icon = <Icon variant='warning' name="status-warning"/>;
      color = WARNING_COLOR;
    }
  } else {
    icon = <Icon variant='success' name="status-positive"/>;
    color = SUCCESS_COLOR;
  }
  return {icon, color};
};

export const getReviewDeadlineConfig = (item) => {
  return getDeadlineConfig(
    item.status === OBLIGATION_STATUS.NotStarted ||
    item.status === OBLIGATION_STATUS.UnderPreparation ||
    item.status === OBLIGATION_STATUS.UnderReview,
    item.intermediateDeadline
  );
};

export const getApprovalDeadlineConfig = (item) => {
  return getDeadlineConfig(
    item.status === OBLIGATION_STATUS.NotStarted ||
    item.status === OBLIGATION_STATUS.UnderPreparation ||
    item.status === OBLIGATION_STATUS.UnderReview ||
    item.status === OBLIGATION_STATUS.UnderApproval,
    item.intermediateDeadline
  );
};

export const getFilingDeadlineConfig = (item) => {
  return getDeadlineConfig(item.status !== OBLIGATION_STATUS.Filed, item.intermediateDeadline);
};

export const DATA_SHARING_USE_CASE_TYPE = new Set(['DATA_SHARING']);
export const DIGITAL_SERVICES_TAX_USE_CASE_TYPE = new Set(['DIGITAL_SERVICES_TAX']);
export const MOG_USE_CASE_TYPE = new Set(['INTRASTAT']);
export const VAT_RETURN_USE_CASE_TYPE = new Set(['VAT_RETURN']);
export const ADHOC_DATA_SHARING_CASE_TYPE = new Set(['ADHOC_DATA_SHARING']);
export const SPAIN_SII_USE_CASE_TYPE = new Set(['SPAIN_SII']);
export const PHASTOS_TAB = {
  DATA_SHARING: "DataSharingTab",
  VAT_RETURNS: "VATReturns",
  MOG: "MovementsOfGood",
  DIGITAL_SERVICES_TAX: "DigitalServicesTax",
  ADHOC_DATA_SHARING: "AdhocDataSharing",
  SPAIN_SII: "SpainSIITab"
};

export const SUCCESS = "SUCCESS";
export const EXPORT_SUPPORTED_TASK_TYPES = ["REPORT_GENERATION", "DATA_AGGREGATION"];
export const DATA_SHARING = "DATA_SHARING";
