import React, {useReducer} from "react";
import Table from "./components/FilingTable";
import {filingReducer, filingReducerInit, INITIAL_STATE} from "./utils/filingReducer";
import Container from "@amzn/awsui-components-react/polaris/container";
import {useLocation} from "react-router-dom";
import { statusColorCodes, TRANSACTION_DETAILS_TABLE_COLUMNS } from "../../common/utils/commonUtils";
import {formatDateWithTime} from "../../common/utils/apiUtils";
import { ColumnLayout } from "@amzn/awsui-components-react";
import {COLOR_RED, TRANSACTION_DETAIL_INFO, UNDERSCORE} from "../../common/constants/constants";
import {fetchCountry} from "./utils/Utils";

function TransactionDetails(params) {
    const [filingData, filingDispatcher] = useReducer(filingReducer,
        INITIAL_STATE, filingReducerInit);

    const location = useLocation();
    const passStateData = location?.state?.data;

    if (!passStateData?.transactionId) {
        return (
            <div className="transactionDetails">
                <Container>
                    <p style={{ color: COLOR_RED, fontWeight: "bold" }}>
                        Transaction ID is missing. Unable to display details.
                    </p>
                </Container>
            </div>
        );
    }

    return (
        <>
            <div className="transactionDetails">
                <Container
                    header={
                        <h2>Transaction Details</h2>
                    }>
                    <Container>
                        <ColumnLayout columns={3}>
                            <div><b>Transaction ID:</b> {passStateData?.transactionId}</div>
                            <div><b>Country:</b> {fetchCountry(passStateData?.useCase) === "Compliance"
                                ? passStateData?.useCase
                                : fetchCountry(passStateData?.useCase)}</div>
                            <div><b>Filing Type:</b> {passStateData?.subUseCase}</div>
                        </ColumnLayout>
                        <ColumnLayout columns={3}>
                        <div><b>Request Created On:</b> {formatDateWithTime(passStateData?.startTimestamp)}</div>
                            <div><b>Status:</b> <span
                                style={statusColorCodes(passStateData?.status)}>{passStateData?.status}</span></div>
                            <div><b>Requested By:</b> {passStateData?.createdBy}</div>
                        </ColumnLayout>
                    </Container>
                </Container>
                <div>
                <div className="table">
                    <Table
                            {...params}
                            table={filingData}
                            tableDispatcher={filingDispatcher}
                            tableHeader="Transaction Submission Summary"
                            visibleColumns={TRANSACTION_DETAILS_TABLE_COLUMNS}
                            transactionId={passStateData?.transactionId}
                            clientId={`${passStateData?.useCase}${UNDERSCORE}${passStateData?.subUseCase}`}
                            tableContext={TRANSACTION_DETAIL_INFO}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionDetails;