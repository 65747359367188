import React, {useState}from "react";

import {FILING_CONFIG, filingRequestAsyncUseCaseList, TRANSMIT_FILE_ASYNC} from '../utils/Constants'
import UploadDownloadBlock from "../components/UploadDownloadBlock";
import ExcelPreviewTable from "../components/ExcelPreviewTable";
import SubmitFiling from "../components/SubmitFiling";
import {processFilingAsyncRequest, submitFilingRequestAsync, uploadFile} from '../utils/filingAPIs';
import {getParentAndSubUseCase} from "../../excalibur/components/fetchAvailableUseCases";
import { CLIENT_NAME } from "../../../common/constants/constants";
import AppConfig from '../../../AppConfig';


function WebBasedFiling(params) {

    const {filingData, filingDispatcher, useCase} = params;

    const [file, setFile] = useState('');
    const [uploading, setUploading] = useState(false);

    const previewTableHeaders = FILING_CONFIG[useCase.useCaseValue].headers;

    const fileValidation = (data) => {
        return FILING_CONFIG[useCase.useCaseValue].fileValidator(data)
    }

    const handleContinueFiling = () => {
        setFile('');
        filingDispatcher({
            type: "RESET_STATE", payload: {
                periodList: [],
                filePath: {},
                flashBarItem: [],
                fileSummaryData: null,
                submitted: false  
            }
        })
    }

    const handleSubmitClick = () => {
        const arr = getParentAndSubUseCase(useCase.useCaseValue);
        const obj = {
            domain: params.domain,
            onUpload: setUploading
        }

        //TODO: This conditional calling will be removed once all useCases of API transmitFileAsync will be migrated to filingRequestAsync
        if(filingRequestAsyncUseCaseList.includes(useCase.useCaseValue)) {
            handleFilingRequestAsync(arr, obj)
        } else {
            handleTransmitFileAsync(arr, obj)
        }
    }

    const handleTransmitFileAsync = (arr, obj) => {
        const filingObj = {
            "useCase": arr[0],
            "clientName": CLIENT_NAME,
            "subUseCase": arr[1],
            "s3BucketName": AppConfig[params.domain].FILING_BUCKET,
            "s3FolderPath": filingData.filePath?.s3BucketUrl
        }

        uploadFile(file, AppConfig[params.domain].FILING_BUCKET, filingData.filePath?.s3BucketUrl, obj.onUpload, obj.domain, submitFilingRequestAsync, filingDispatcher, filingObj, handleContinueFiling);

    }

    const handleFilingRequestAsync = (arr, obj) => {
        const filingObj = {
            "useCase": arr[0],
            "clientName": CLIENT_NAME,
            "subUseCase": arr[1],
            "operationType": TRANSMIT_FILE_ASYNC,
            "additionalDataMap": {
                "s3BucketName": AppConfig[params.domain].FILING_BUCKET,
                "s3FolderPath": filingData.filePath?.s3BucketUrl
            }
        }
        uploadFile(file, AppConfig[params.domain].FILING_BUCKET, filingData.filePath?.s3BucketUrl, obj.onUpload, obj.domain, processFilingAsyncRequest, filingDispatcher, filingObj, handleContinueFiling);
    }

    return (
        <>
            <div>
                <UploadDownloadBlock 
                    {...params} 
                    file={file}
                    setFile={setFile}
                    isDisabled={!useCase.isSelected}
                    fileValidation={fileValidation}
                    handleFlashBarAction={handleContinueFiling}
                    workSheetIndex={0}/>
                <br/>
            </div>
            {filingData.fileSummaryData ? 
            <>
                <ExcelPreviewTable tableTitle={"Tax Summary"} header={previewTableHeaders} data={filingData.fileSummaryData}/> 
                <br/>
                <SubmitFiling disabled={file===""} uploading={uploading} handleSubmitClick={handleSubmitClick} useCase={useCase}/>
            </>
            : null }
        </>
    );
}

export default WebBasedFiling;