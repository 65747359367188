import { Link } from "@amzn/awsui-components-react";
import { getCurrencyAmountForSummary } from "../../common/adjustment/constants/errorSummaryUtils";

export const getColumnDefinitions = (handleShowTransactions, currencySymbol) => {
    return [
        {
            id: 'transactionType',
            header: 'Type',
            cell: item => {
                if (item.transactionType === "Excluded" || item.exceptionStatus === "Unresolved") {
                    return (
                        <Link onFollow={() => handleShowTransactions({
                            type: item.transactionType,
                        })}>{item.transactionType}</Link>
                    );
                }
            },
            minWidth: '144px'
        },
        {
            id: 'transactionCategory',
            header: 'Reason Code',
            cell: item => {
                if (item.transactionType === "Excluded" || item.exceptionStatus === "Unresolved") {
                    return (
                        <Link onFollow={() => handleShowTransactions({
                            type: item.transactionType,
                            category: item.transactionCategory,
                        })}>{item.transactionCategory}</Link>
                    );
                }
            },
            minWidth: '128px',
        },
        {
            id: 'severity',
            header: 'Severity',
            cell: item => {
                if (item.transactionType === "Excluded" || item.exceptionStatus === "Unresolved") {
                    return item.severity;
                }
            },
            minWidth: '128px'
        },
        {
            id: 'exceptionStatus',
            header: 'Status',
            cell: item => {
                return (
                    <Link onFollow={() => handleShowTransactions({
                        type: item.transactionType,
                        category: item.transactionCategory,
                        status: item.exceptionStatus,
                    })}>{item.exceptionStatus}</Link>
                );
            },
            minWidth: '128px',
        },
        {
            id: 'transactionCount',
            header: 'Count',
            cell: item => {
                const count  = parseInt(item.transactionCount);
                const parsedCount = count.toLocaleString("en-US");
                return <div style={{float: "right"}}>{parsedCount}</div>;
            },
            minWidth: '108px',
        },
        {
            id: 'taxBase',
            header: 'Tax Base',
            cell: item => {
                return getCurrencyAmountForSummary(item.taxBase, currencySymbol);
            },
            minWidth: '160px',
        }
    ];
};

export const SPAIN_SII_TRANSACTION_STATUS_MAPPING ={
    'Review-UnResolved': 'Unresolved',
    'Review-Confirmed': 'Review-Accepted',
    'Review-Corrected': 'Review-Adjusted',
    'Reportable': 'Reportable',
    'Excluded': 'Excluded',
    'Unresolved': 'Unresolved',
    'Review-Accepted': 'Review-Accepted',
    'Review-Adjusted': 'Review-Adjusted',
    'Corrected': 'Adjusted',
    'Confirmed': 'Accepted'
}
