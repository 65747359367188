import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    getMatchesCountText,
    paginationLabels,
    Preferences,
    PROPERTY_FILTERING_I18N_CONSTANTS
} from "../../table-config";
import {
    Button,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { isNull } from "lodash";
import { Checkbox } from "@amzn/awsui-components-react/polaris";
import { OBLIGATION_STATUS } from '../../../exception/components/common/constants';
import { getColumnDefinitions } from './table-config';
import ViewSummaryMetricsForSPAIN from "./ViewSummaryMetrics";

export default function SpainSIITab(props) {
    const navigate = useNavigate();
    const {
        viewSummaryMetricsProps,
        collectionProps,
        selectedItems,
        allItems,
        preferences,
        items,
        paginationProps,
        propertyFilterProps,
        filteredItemsCount,
        setPreferences,
        setSelectedItems,
        statusFilterValue,
        actions,
        isSPAINFiledChecked,
        setIsSPAINFiledChecked,
        createChannelSlackStatusDispatcher,
        addUserSlackStatusDispatcher,
        listChannelsSlackData,
        listChannelsSlackDataDispatcher
    } = props;

    const columnDefinitions = getColumnDefinitions(
        navigate, createChannelSlackStatusDispatcher, addUserSlackStatusDispatcher, listChannelsSlackData, listChannelsSlackDataDispatcher
    );

    const onSelectionChange = (item) => {
        setSelectedItems(item.detail.selectedItems)
    };

    const {setPropertyFiltering} = actions;

    useEffect(() => {
        if (!isNull(statusFilterValue.spainTab)) {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [{
                    propertyKey: 'status',
                    operator: '=',
                    value: statusFilterValue.spainTab,
                }],
            })
        } else {
            setPropertyFiltering({
                ...propertyFilterProps.query,
                tokens: [],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilterValue]);

    const isFilingBtnDisabled = () => {
        if (selectedItems.length === 1) {
            if (selectedItems[0].status === OBLIGATION_STATUS.UnderFiling) {
                return false;
            }
        }
        return true;
    }

    const filingBtnHandler = () => {
        navigate("../../filing", {replace: false});
    }

    const getSpainSIITableActions = () => {
        return (
            <SpaceBetween size='l' direction='horizontal'>
                <Checkbox checked={isSPAINFiledChecked} onChange={event => { setIsSPAINFiledChecked(event.detail.checked) }}>Include filed obligations</Checkbox>
                <Button disabled={isFilingBtnDisabled()} onClick={filingBtnHandler}>Start filing</Button>
            </SpaceBetween>
        );
    };

    return (
        <SpaceBetween size="xl">
            <ViewSummaryMetricsForSPAIN
                summaryDataTitle={viewSummaryMetricsProps.summaryDataTitle}
                changeStatusFilterValue={viewSummaryMetricsProps.changeStatusFilterValue}
                tableData={viewSummaryMetricsProps.tableData} />
            <Table
                {...collectionProps}
                selectionType="multi"
                onSelectionChange={onSelectionChange}
                header={
                    <div>
                        <Header
                            counter={selectedItems.length ? `(${selectedItems.length}/${allItems.length})` : `(${allItems.length})`}
                            actions={getSpainSIITableActions()}
                        >
                            Obligations
                        </Header>
                    </div>
                }
                columnDefinitions={columnDefinitions}
                visibleColumns={preferences.visibleContent}
                selectedItems={selectedItems}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                filter={
                    <PropertyFilter
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        filteringProperties={propertyFilterProps.filteringProperties}
                        filteringOptions={propertyFilterProps.filteringOptions}
                        onChange={propertyFilterProps.onChange}
                        query={propertyFilterProps.query}
                        countText={getMatchesCountText(filteredItemsCount)}
                    />
                }
                preferences={<Preferences preferences={preferences} setPreferences={setPreferences}/>}
            />
        </SpaceBetween>
    );
}