import {getCurrencyAmountForSummary} from "../exception/components/common/adjustment/constants/errorSummaryUtils";
import { PHASTOS_OBLIGATION } from "../exception/components/common/constants";
import {
    getFormattedProgress,
    getFormattedStatus,
    getFormattedCount
} from "./utils/utils";

export const getColumnDefinitions = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return [
                {
                    id: "sourceType",
                    header: "Use Case",
                    cell: item => item.sourceType || "-",
                    sortingField: "sourceType"
                },
                {
                    id: "txnStatus",
                    header: "Transaction Status",
                    cell: item => item.transactionStatus || "-",
                    sortingField: "txnStatus"
                },
                {
                    id: "count",
                    header: "Count",
                    cell: item => parseInt(item.transactionCount).toLocaleString("en-US") || "-",
                    sortingField: "count"
                },
                {
                    id: "invoiceValue",
                    header: "Invoice value (local currency)",
                    cell: item => getCurrencyAmountForSummary(item.invoiceValue) || "-",
                    sortingField: "invoiceValue"
                },
                {
                    id: "statisticalValue",
                    header: "Statistical value (local currency)",
                    cell: item => getCurrencyAmountForSummary(item.statisticalValue) || "-",
                    sortingField: "statisticalValue"
                }
            ];
        case PHASTOS_OBLIGATION.SPAIN_SII:
            return [
                {
                    id: "sourceType",
                    header: "Use Case",
                    cell: item => item.sourceType || "-",
                    sortingField: "sourceType"
                },
                {
                    id: "transactionStatus",
                    header: "Transaction Status",
                    cell: item => item.transactionStatus || "-",
                    sortingField: "transactionStatus"
                },
                {
                    id: "exceptionCount",
                    header: "Exception Count",
                    cell: item => parseInt(item.exceptionCount).toLocaleString("en-US") || "-",
                    sortingField: "exceptionCount"
                },
                {
                    id: "transactionCount",
                    header: "Transaction Count",
                    cell: item => parseInt(item.transactionCount).toLocaleString("en-US") || "-",
                    sortingField: "transactionCount"
                },
                {
                    id: "taxBase",
                    header: "Tax Base (local currency)",
                    cell: item => getCurrencyAmountForSummary(item.taxBase) || "-",
                    sortingField: "taxBase"
                }
            ];
        case PHASTOS_OBLIGATION.VAT:
        default:
            return [
                {
                    id: "sourceType",
                    header: "Source Type",
                    cell: item => item.sourceType || "-",
                    sortingField: "sourceType"
                },
                {
                    id: "txnStatus",
                    header: "Transaction Status",
                    cell: item => item.transactionStatus || "-",
                    sortingField: "txnStatus"
                },
                {
                    id: "count",
                    header: "Count",
                    cell: item => parseInt(item.transactionCount).toLocaleString("en-US") || "-",
                    sortingField: "count"
                },
                {
                    id: "netAmount",
                    header: "Net Amount (AUD)",
                    cell: item => isNaN(item.netAmount) ? "-" : parseFloat(item.netAmount).toLocaleString("en-US") || "-",
                    sortingField: "netAmount"
                },
                {
                    id: "netTax",
                    header: "Net Tax (AUD)",
                    cell: item => isNaN(item.netTax) ? "-" : parseFloat(item.netTax).toLocaleString("en-US") || "-",
                    sortingField: "netTax"
                },
                {
                    id: "grossAmount",
                    header: "Gross Amount (AUD)",
                    cell: item => isNaN(item.netGrossAmount) ? "-" : parseFloat(item.netGrossAmount).toLocaleString("en-US") || "-",
                    sortingField: "grossAmount"
                }
            ];
    }
}

export const getSourceSummaryColumnDef = (phastosObligation) => {
    switch (phastosObligation) {
        case PHASTOS_OBLIGATION.MOG:
            return [
                {
                    id: "sourceType",
                    header: "Use case",
                    cell: e => e.sourceType
                },
                {
                    id: "status",
                    header: "Status",
                    cell: e => getFormattedStatus(e.exceptionCount, e.transactionCount, PHASTOS_OBLIGATION.MOG)
                },
                {
                    id: "progress",
                    header: "Progress",
                    cell: e => getFormattedProgress(e.progress),
                    sortingField: "progress"
                },
                {
                    id: "transactionCount",
                    header: "Transaction count",
                    cell: e => getFormattedCount(e.transactionCount),
                    sortingField: "transactionCount",
                    sortingComparator: (s1, s2) => s1.transactionCount - s2.transactionCount
                },
                {
                    id: "exceptionCount",
                    header: "Exception count",
                    cell: e => getFormattedCount(e.exceptionCount),
                    sortingField: "exceptionCount",
                    sortingComparator: (s1, s2) => s1.exceptionCount - s2.exceptionCount
                },
                {
                    id: "invoiceValue",
                    header: "Invoice value (local currency)",
                    cell: item => getCurrencyAmountForSummary(item.invoiceValue) || "-",
                    sortingField: "invoiceValue",
                    sortingComparator: (s1, s2) => s1.invoiceValue - s2.invoiceValue
                },
                {
                    id: "statisticalValue",
                    header: "Statistical value (local currency)",
                    cell: item => getCurrencyAmountForSummary(item.statisticalValue) || "-",
                    sortingField: "statisticalValue",
                    sortingComparator: (s1, s2) => s1.statisticalValue - s2.statisticalValue
                },
                {
                    id: "reportSource",
                    header: "Ingestion type",
                    cell: e => e.reportSource
                }
            ];
        case PHASTOS_OBLIGATION.SPAIN_SII:
            return [
                {
                    id: "sourceType",
                    header: "Use case",
                    cell: e => e.sourceType
                },
                {
                    id: "status",
                    header: "Status",
                    cell: e => getFormattedStatus(e.exceptionCount, e.transactionCount, PHASTOS_OBLIGATION.SPAIN_SII)
                },
                {
                    id: "progress",
                    header: "Progress",
                    cell: e => getFormattedProgress(e.progress),
                    sortingField: "progress"
                },
                {
                    id: "transactionCount",
                    header: "Transaction count",
                    cell: e => getFormattedCount(e.transactionCount),
                    sortingField: "transactionCount",
                    sortingComparator: (s1, s2) => s1.transactionCount - s2.transactionCount
                },
                {
                    id: "exceptionCount",
                    header: "Exception count",
                    cell: e => getFormattedCount(e.exceptionCount),
                    sortingField: "exceptionCount",
                    sortingComparator: (s1, s2) => s1.exceptionCount - s2.exceptionCount
                },
                {
                    id: "taxBase",
                    header: "Tax Base (local currency)",
                    cell: item => getCurrencyAmountForSummary(item.taxBase) || "-",
                    sortingField: "taxBase",
                    sortingComparator: (s1, s2) => s1.taxBase - s2.taxBase
                },
                {
                    id: "reportSource",
                    header: "Ingestion type",
                    cell: e => e.reportSource
                }
            ];
        case PHASTOS_OBLIGATION.VAT:
        default:
            return [
                {
                    id: "sourceType",
                    header: "Source Type",
                    cell: e => e.sourceType
                },
                {
                    id: "status",
                    header: "Status",
                    cell: e => getFormattedStatus(e.exceptionCount, e.transactionCount, PHASTOS_OBLIGATION.VAT)
                },
                {
                    id: "progress",
                    header: "Progress",
                    cell: e => getFormattedProgress(e.progress),
                    sortingField: "progress"
                },
                {
                    id: "transactionCount",
                    header: "#Total Transactions",
                    cell: e => getFormattedCount(e.transactionCount),
                    sortingField: "transactionCount",
                    sortingComparator: (s1, s2) => s1.transactionCount - s2.transactionCount
                },
                {
                    id: "exceptionCount",
                    header: "#Exception transaction",
                    cell: e => getFormattedCount(e.exceptionCount),
                    sortingField: "exceptionCount",
                    sortingComparator: (s1, s2) => s1.exceptionCount - s2.exceptionCount
                }
            ];
    }
};

export const getSourceSummaryVisibleContentDef = (phastosObligation) => {
    if (phastosObligation === PHASTOS_OBLIGATION.MOG) {
        return {
            title: "Select visible content",
            options: [
                {
                    label: "Available Attributes",
                    options: [
                        {
                            id: "sourceType",
                            label: "Use case",
                            editable: false
                        },
                        {id: "status", label: "Status"},
                        {id: "progress", label: "Progress"},
                        {id: "transactionCount", label: "Transaction count"},
                        {id: "exceptionCount", label: "Exception count"},
                        {id: "invoiceValue", label: "Invoice value (local currency)"},
                        {id: "statisticalValue", label: "Statistical value (local currency)"}
                    ]
                }
            ]
        };
    } else if (phastosObligation === PHASTOS_OBLIGATION.SPAIN_SII) {
        return {
            title: "Select visible content",
            options: [
                {
                    label: "Available Attributes",
                    options: [
                        {
                            id: "sourceType",
                            label: "Use case",
                            editable: false
                        },
                        {id: "status", label: "Status"},
                        {id: "progress", label: "Progress"},
                        {id: "transactionCount", label: "Transaction count"},
                        {id: "exceptionCount", label: "Exception count"},
                        {id: "taxBase", label: "Tax Base (local currency)"}
                    ]
                }
            ]
        };
    } else {
        return {
            title: "Select visible content",
            options: [
                {
                    label: "Available Attributes",
                    options: [
                        {
                            id: "sourceType",
                            label: "Source",
                            editable: false
                        },
                        {id: "status", label: "Status"},
                        {id: "progress", label: "Progress"},
                        {id: "transactionCount", label: "#Total transactions"},
                        {id: "exceptionCount", label: "#Exception transactions"},
                    ]
                }
            ]
        };
    }
}

export const getSourceSummaryFilteringProperties = (phastosObligation) => {
    if (phastosObligation === PHASTOS_OBLIGATION.MOG || phastosObligation === PHASTOS_OBLIGATION.SPAIN_SII) {
        return [
            {
                propertyLabel: 'Use case',
                key: 'sourceType',
                groupValuesLabel: 'Source',
                operators: [':', '!:', '=', '!='],
            },
        ];
    } else {
        return [{
            propertyLabel: 'Source',
            key: 'sourceType',
            groupValuesLabel: 'Source',
            operators: [':', '!:', '=', '!='],
        }];
    }
};
