import React, { useEffect, useState } from "react";
import { Button, Header, Pagination, PropertyFilter, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { EmptyState, getMatchesCountText, PROPERTY_FILTERING_I18N_CONSTANTS } from "../../../phastos/table-config";
import { Preferences } from "./constants/table-config";
import { postMessage } from "../../../phastos/components/Slack/slackIntegrationAPIs";
import { getUserAlias } from "../../utils/utils";
import { getReportingAggregatedDataForUseCase, modifyWorkflowStatus} from "../redux/exceptionAPIs";
import { ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY, ASM_COMPLETE_STATUS } from "../common/constants";
import "./css/style.css"
import {
    getColumnDefinitions,
    getFilteringProperties,
    getPreferenceOptions,
    getVisibleContent
} from "./constants/table-config";
import { isNull, isUndefined, max } from "lodash";

function MOGSummary(props) {
    const {
        slackChannel,
        parentWorkflowId,
        useCase,
        lastUpdatedOn,
        setLastUpdatedOn,
        setActiveTabId,
        setStepsStateHandler,
        updateWorkflowStatusDispatcher,
        isUnderReview,
        stepsState
    } = props;

    const [data, setData] = useState([]);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [loading, setLoading] = useState(false);
    const refreshData = (pageNumber) => {
        setLoading(true);
        const request = {
            data: data,
            setData: setData,
            setLastUpdatedOn: setLastUpdatedOn,
            lastEvaluatedKey: lastEvaluatedKey,
            setLastEvaluatedKey: setLastEvaluatedKey,
            parentWorkflowId: parentWorkflowId,
            useCase: useCase,
            currentPageNumber: pageNumber,
            setOpenEnd: setOpenEnd,
            setLoading: setLoading
        };
        getReportingAggregatedDataForUseCase(request);
    }

    useEffect(() => {
        refreshData(INITIAL_PAGE_NUMBER);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [completeButtonState, setCompleteButtonState] = useState(false);

    const completeButtonClickHandler = async () => {
        setCompleteButtonState(true);
        const userAlias = await getUserAlias();
        const request = {
            parentWorkflowId: parentWorkflowId,
            acknowledgementStructureMap: {
                [ACKNOWLEDGEMENT_STRUCTURE_MAP_KEY.Summary]: { status: ASM_COMPLETE_STATUS, user: userAlias }
            },
        };
        const onSuccess = () => {
            postMessage(slackChannel, `${userAlias}@ has completed the Summary step.`);
            setStepsStateHandler("summaryStep");
        };
        modifyWorkflowStatus(updateWorkflowStatusDispatcher, request, onSuccess);
        setCompleteButtonState(false);
    };

    const [preferences, setPreferences] = useState({
        pageSize: 10,
        visibleContent: getVisibleContent()
    });

    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [openEnd, setOpenEnd] = useState(true);

    useEffect(() => {
        if (!isNull(data[currentPageNumber]) && !isUndefined(data[currentPageNumber])) {
            setCurrentPageData(data[currentPageNumber]);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // eslint-disable-next-line no-unused-vars
    const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCollection(
        currentPageData,
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No summary"
                        subtitle="No summary to display."
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                    />
                ),
            },
            propertyFiltering: {
                filteringProperties: getFilteringProperties(),
                empty: <EmptyState title="No matches" />,
                noMatch: (
                    <EmptyState
                        title="No matches"
                    />
                ),
            },
            selection: {}
        }
    );

    const isCompleteButtonDisabled = () => {
        return !(isUnderReview && !stepsState.summaryStep.completed);
    };

    const INITIAL_PAGE_NUMBER = 1;
    const [currentPageNumber, setCurrentPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [totalPageNumber, setTotalPageNumber] = useState(INITIAL_PAGE_NUMBER);

    const onPaginationChange = (event) => {
        const pageNumber = event.detail.currentPageIndex;
        setCurrentPageNumber(pageNumber);
        setTotalPageNumber(max([totalPageNumber, pageNumber]));

        if (isNull(data[pageNumber]) || isUndefined(data[pageNumber])) {
            refreshData(pageNumber);
        } else {
            setCurrentPageData(data[pageNumber]);
            setLoading(false);
        }
    }

    return (
        <SpaceBetween size="xl">
            <div className="intrastatSummaryReport">
                <Table
                    {...collectionProps}
                    header={
                        <div>
                            <Header
                                actions={<SpaceBetween size='xs' direction='horizontal'>
                                    <Button onClick={() => {refreshData(currentPageNumber)}}>Refresh</Button>
                                    <Button onClick={()=> {setActiveTabId("second")}}>View breakdown</Button>
                                    <Button onClick={completeButtonClickHandler}
                                            disabled={isCompleteButtonDisabled() || completeButtonState}> Mark as complete</Button>
                                </SpaceBetween>}
                                description={`Last updated: ${lastUpdatedOn}`}
                            >
                                Intrastat Report Summary
                            </Header>
                        </div>
                    }
                    pagination={<Pagination
                        currentPageIndex={currentPageNumber}
                        onChange={onPaginationChange}
                        pagesCount={totalPageNumber}
                        openEnd={openEnd}
                    />}
                    columnDefinitions={getColumnDefinitions(useCase)}
                    resizableColumns
                    visibleColumns={preferences.visibleContent}
                    items={items}
                    loading={loading}
                    trackBy={"hashKey"}
                    preferences={<Preferences preferences={preferences} setPreferences={setPreferences} visibleContentOptions={[
                        {
                            label: 'Summary properties',
                            options: getPreferenceOptions(),
                        },
                    ]}/>}
                    filter={
                        <PropertyFilter
                            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                            filteringProperties={propertyFilterProps.filteringProperties}
                            filteringOptions={propertyFilterProps.filteringOptions}
                            onChange={propertyFilterProps.onChange}
                            query={propertyFilterProps.query}
                            countText={getMatchesCountText(filteredItemsCount)}
                        />
                    }
                />
            </div>
        </SpaceBetween>
    );
}

export default MOGSummary;