import React, { useEffect } from "react";
import {
    PropertyFilter,
    Pagination,
    Box,
    Header,
    Table,
    Button,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react/polaris";
import { getExcaliburAuditData, getTransactionAuditData } from "../../excalibur/components/excaliburAPIs";
import { formatDateWithTime } from "../../../common/utils/apiUtils";
import { statusColorCodes } from '../../../common/utils/commonUtils';
import {PROPERTY_FILTERING_I18N_CONSTANTS} from '../../phastos/table-config';
import {downloadFolder, uploadFiles} from "../../../common/utils/s3Utils";
import {
    ARTIFACTS_FORMAT,
    REMOTE_FILING_TRANSACTION_TYPE,
    COLOR_RED,
    EXCALIBUR_AUDIT_INFO,
    TRANSACTION_DETAIL_INFO
} from "../../../common/constants/constants";
import AppConfig from "../../../AppConfig";
import {getFilingReceipt} from "../utils/filingAPIs";
import Link from "@amzn/awsui-components-react/polaris/link";
import CopyToClipboard from "@amzn/awsui-components-react/polaris/copy-to-clipboard";
import { ROUTE_PATH_TRANSACTION_DETAILS_PAGE } from "../../../common/constants/route_paths";
import { useNavigate } from "react-router-dom";

const Actiontable = (params) => {

    const { table, tableDispatcher, tableHeader, visibleColumns, transactionId, clientId, tableContext } = params;
    const [loading, setLoading] = React.useState(false);
    const [query, setQuery] = React.useState({
        tokens: [],
        operation: "and"
    });

    const navigate = useNavigate();

    const downloadArtifacts = async (inputFileKey, s3Bucket) => {
        setLoading(true);
        try {
            await downloadFolder(inputFileKey, s3Bucket, params.domain)
        } catch (error) {
            tableDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Error occured while downloading the file',
                    type: 'error',
                    dispatcher: tableDispatcher
                }
            })
        }
        setLoading(false);
    }

    const downloadSubmissionReceipt = (audit) => {
        if(audit.generatedFileKey){
            downloadArtifacts(audit.generatedFileKey, AppConfig[params.domain].FILING_RECEIPT_BUCKET);
        } else {
            getFilingReceipt(tableDispatcher, audit.useCase, audit.subUseCase, audit.transactionId, params.domain, setLoading);
        }
    }

    const uploadArtifacts = async (audit, files) => {
        setLoading(true);
        try {
            await uploadFiles(files, AppConfig[params.domain].ARTIFACTS_BUCKET, audit.inputFileKey, params.domain)
        } catch (error) {
            tableDispatcher({
                type: "SET_FLASH_BAR", payload: {
                    msg: 'Error occured while uploading the file',
                    type: 'error',
                    dispatcher: tableDispatcher
                }
            })
        }
        setLoading(false);
    }

    const navigateToTransactionDetailsPage = (transaction) => {
        navigate(ROUTE_PATH_TRANSACTION_DETAILS_PAGE, {
            state: {
                data: transaction
            }
        });
    }

    const renderErrorDetails = (record) => {
        if (record.status === 'FAILURE') {
            return (
                <SpaceBetween direction="horizontal" size="xs">
                    <div style={{ color: COLOR_RED }}>{generateErrorDetailsMessage(record.additionalDataMap)}</div>
                    <Link onClick={() => navigateToTransactionDetailsPage(record)}>More Info</Link>
                </SpaceBetween>
            );
        }
        return null;
    };

    const generateErrorDetailsMessage = (additionalDataMap) => {
        if (additionalDataMap?.totalTransactions && additionalDataMap?.successfulTransactions) {
            const totalTransactions = additionalDataMap.totalTransactions;
            const successfulTransactions = additionalDataMap.successfulTransactions;
            return `${totalTransactions - successfulTransactions}/${totalTransactions} filings have errors.`;
        }
        return 'Some filings have errors';
    };

    useEffect(() => {
        const fetchData = async () => {
            if (table.initialize) {
                if (tableContext === TRANSACTION_DETAIL_INFO) {
                    await getTransactionAuditData(tableDispatcher,
                        table.lastEvaluatedKey,
                        REMOTE_FILING_TRANSACTION_TYPE,
                        transactionId,
                        clientId);
                } else if (tableContext === EXCALIBUR_AUDIT_INFO) {
                    await getExcaliburAuditData(tableDispatcher, table.lastEvaluatedKey, REMOTE_FILING_TRANSACTION_TYPE);
                }
            }
        };

        fetchData();
    }, [table.initialize, table.lastEvaluatedKey, transactionId, clientId, tableContext, tableDispatcher]);

    return (
        <>
            {loading ? <Spinner className={"appLoader"} size="large" variant={"disabled"}>Loading</Spinner> : null}
        <Table
            loading={table.loading}
            loadingText={table.loadingText}
            onSortingChange={((event)=>{
                tableDispatcher({type:"AUDIT_SORTING_CHANGED",
                    payload:{isDescending:event.detail.isDescending,
                        sortingField:event.detail.sortingColumn.sortingField}});
            })}
            sortingColumn={table.sortingColumn}
            sortingDescending={table.sortingDescending}
            columnDefinitions={[

                {
                    id: "useCase",
                    header: "Country",
                    cell: record => record.useCase,
                    sortingField: "useCase"
                },
                {
                    id: "subUseCase",
                    header: "Filing Type",
                    cell: record => record.subUseCase,
                    sortingField: "subUseCase"

                },
                {
                    id: "transactionId",
                    header: "Transaction Id",
                    cell: record => (
                        <div>
                            <Link onClick={() => navigateToTransactionDetailsPage(record)}>
                                {record.transactionId}
                            </Link>
                            <CopyToClipboard
                                copyErrorText="Failed to copy!"
                                copySuccessText="Attribute Copied"
                                textToCopy={record.transactionId}
                                variant="icon"
                            />
                        </div>
                    ),
                    sortingField: "transactionId"
                },
                {
                    id: "taxIdentifier",
                    header: "Tax Identifier",
                    cell: record => record.additionalDataMap?.VRN
                },
                {
                    id: "status",
                    header: "Status",
                    cell: record => {
                        return (<div style={statusColorCodes(record.status)}>{record.status}</div>)
                    },
                },
                {
                    id: "startTimestamp",
                    header: "Request Created on",
                    cell: record => formatDateWithTime(record.startTimestamp),
                    sortingField: "startTimestamp"
                },
                {
                    id: "createdBy",
                    header: "Submitted By",
                    cell: record => record.createdBy,
                },
                {
                    id: "inputFileKey",
                    header: "Input File",
                    cell: record => {
                        return (
                                <Button iconName="download" variant={"link"}
                                    onClick={() => downloadArtifacts(record.inputFileKey, record.inputFileBucket)}>File</Button>
                        )
                    },
                    minWidth: 150
                },
                {
                    id: "submissionDetails",
                    header: "Submission Details",
                    cell: record => JSON.stringify(record.additionalDataMap),
                },
                {
                    id: "getReceipt",
                    header: "Submission Receipt",
                    cell: record => {
                        return (
                            <Button iconName="download" variant={"link"} onClick={() => downloadSubmissionReceipt(record)}>Receipt</Button>
                        )
                    },
                    minWidth: 150
                },
                {
                    id: "errorDetails",
                    header: "Error Details",
                    cell: record => record.errorDetails,
                    minWidth: 400
                },
                {
                    id: "transactionDetails",
                    header: "Error Details",
                    cell: record => renderErrorDetails(record),
                    minWidth: 400
                },
                {
                    id: "txnArtifacts",
                    header: "Artifacts",
                    cell: record => {
                        return (
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button iconName="download"  onClick={() =>
                                    downloadArtifacts(record.inputFileKey, AppConfig[params.domain].ARTIFACTS_BUCKET)}>View</Button>
                                <Button iconName="upload" variant="primary">
                                    <input className="inputButton hidden" type={"file"} accept={ARTIFACTS_FORMAT} name="file" onChange={(event) => uploadArtifacts(record, event.target.files)} multiple/>
                                    Upload
                                </Button>
                            </SpaceBetween>
                        )
                    },
                    minWidth: 300
                }
            ]}
            visibleColumns={visibleColumns}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No files</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit">
                        No files to display.
                    </Box>

                </Box>
            }
            filter={
                <div>
                    <Header>
                        {tableHeader}
                    </Header>
                    <PropertyFilter
                        onChange={({ detail }) => setQuery(detail)}
                        query={query}
                        i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
                        countText="5 matches"
                        expandToViewport
                        filteringOptions={[
                            {
                                propertyKey: "useCase",
                                value: "UK"
                            },
                            {
                                propertyKey: "subUseCase",
                                value: "VAT_FILING"
                            },
                            {
                                propertyKey: "status",
                                value: "SUCCESS"
                            },
                            {
                                propertyKey: "status",
                                value: "FAILURE"
                            },
                            {
                                propertyKey: "status",
                                value: "COMPLETED"
                            },
                        ]}
                        filteringProperties={[
                            {
                                key: "useCase",
                                operators: ["=", "!=", ":", "!:"],
                                propertyLabel: "Country",
                                groupValuesLabel: ""
                            },
                            {
                                key: "subUseCase",
                                operators: ["=", "!=", ":", "!:"],
                                propertyLabel: "Filing Type",
                                groupValuesLabel: "Sub Use Case"
                            },
                            {
                                key: "status",
                                operators: ["=", "!=", ":", "!:"],
                                propertyLabel: "Status",
                                groupValuesLabel: "Status"
                            }
                        ]} />
                </div>
            }
            items={table.auditData.get(table.currentPageIndex)}
            pagination={
                <Pagination
                    currentPageIndex={table.currentPageIndex}
                    pagesCount={table.pageCount}
                    onChange={event => {
                        tableDispatcher({type:"PAGE_INDEX_CHANGED",
                            payload:{pageIndex:event.detail.currentPageIndex, dispatcher:tableDispatcher}})
                    }
                    }
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                            `Page ${pageNumber} of all pages`
                    }}
                />
            }
            preferences={
                <Button iconName="refresh" variant="icon"
                        onClick={()=>{
                            tableDispatcher({type:"REFRESH_AUDIT",payload:{dispatcher:tableDispatcher}})
                        }}
                />
            }
        />
        </>
    );
}

export default Actiontable;