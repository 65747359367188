import { get } from "lodash";
import { 
    ROUTE_DOCUMENT_MANAGER,
    ROUTE_EXCALIBUR,
    ROUTE_PATH_FAQ_PAGE,
    ROUTE_PATH_FILING_PAGE,
    ROUTE_PATH_HOME_PAGE,
    ROUTE_PATH_PHASTOS_PAGE, 
    ROUTE_SOURCE_SUMMARY,
    ROUTE_PATH_PHASTOS_DETAILS_PAGE,
    ROUTE_PATH_TRANSACTION_DETAILS_PAGE
} from "./route_paths";

export const getBreadCrumbItems = (pageName, params) => {
    return BRC_ITEMS_PAGE[pageName](params);
}

const BRC_ITEMS_PAGE = {
    Home: getHomeBRCItems,
    Faq: getFaqBRCItems,
    Excalibur: getExcaliburBRCItems,
    Phastos: getPhastosBRCItems,
    Exception: getExceptionBRCItems,
    Filing: getFilingBRCItems,
    SourceSummary: getSourceSummaryBRCItems,
    DocumentManagement: getDocumentManagementBRCItems,
    TransactionDetails: getTransactionDetailsBRCItems,
}

function getHomeBRCItems() {
    return [];
}

function getFaqBRCItems() {
    return [getHomePageBRCItem(), getFAQPageBRCItem()];
}

function getExcaliburBRCItems() {
    return [getHomePageBRCItem(), getExcaliburPageBRCItem()];
}

function getPhastosBRCItems() {
    return [getHomePageBRCItem(), getPhastosPageBRCItem()];
}

function getExceptionBRCItems(params) {
    return [getHomePageBRCItem(), getPhastosPageBRCItem(), getExceptionPageBRCItem(params)];
}

function getFilingBRCItems() {
    return [getHomePageBRCItem(), getFilingPageBRCItem()];
}

function getSourceSummaryBRCItems(params) {
    return [getHomePageBRCItem(), getPhastosPageBRCItem(), getSourceSummaryPageBRCItem(params)];
}

function getDocumentManagementBRCItems() {
    return [getHomePageBRCItem(), getDocumentManagerPageBRCItem()];
}

function getTransactionDetailsBRCItems() {
    return [getHomePageBRCItem(), getFilingPageBRCItem(), getTransactionDetailsPageBRCItem()];
}

function getHomePageBRCItem() {
    return {
        text: 'Home',
        href: ROUTE_PATH_HOME_PAGE
    }
}

function getFAQPageBRCItem() {
    return {
        text: 'FAQ',
        href: ROUTE_PATH_FAQ_PAGE
    }
}

function getExcaliburPageBRCItem() {
    return {
        text: 'Excalibur',
        href: ROUTE_EXCALIBUR
    }
}

function getPhastosPageBRCItem() {
    return {
        text: 'Phastos',
        href: ROUTE_PATH_PHASTOS_PAGE
    }
}

function getExceptionPageBRCItem(params) {
    const itemData = get(params, 'state.data', {});
    return {
        text: [
            `${get(itemData, 'obligationName', '')} ${get(itemData, 'identifierMap.useCaseType', '')}`,
            get(itemData, 'country', ''),
            get(itemData, 'entity', ''),
            get(itemData, 'reportingPeriod', '')
        ].join(' - '),
        href: ROUTE_PATH_PHASTOS_DETAILS_PAGE
    }
}

function getFilingPageBRCItem() {
    return {
        text: 'Filing',
        href: ROUTE_PATH_FILING_PAGE
    }
}

function getDocumentManagerPageBRCItem() {
    return {
        text: 'Document manager',
        href: ROUTE_DOCUMENT_MANAGER
    }
}

function getTransactionDetailsPageBRCItem() {
    return {
        text: 'Transaction Details',
        href: ROUTE_PATH_TRANSACTION_DETAILS_PAGE
    }
}

function getSourceSummaryPageBRCItem(params) {
    const itemData = get(params, 'state.data', {});
    return {
        text: [
            get(itemData, 'obligationName', ''),
            get(itemData, 'country', ''),
            get(itemData, 'entity', ''),
            get(itemData, 'reportingPeriod', ''), 'Source Summary'
        ].join(' - '),
        href: ROUTE_SOURCE_SUMMARY
    }
}